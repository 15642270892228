import React from "react";
import { useHistory } from "react-router-dom"; // Import useHistory
import { Box, Flex, Text, IconButton, Button, useColorMode, Spinner } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";

const GenerationActions = ({ selectedProgram, selectedRow, handleGenerateContent, isGenerating }) => {
  const { colorMode } = useColorMode();
  const history = useHistory(); // Initialize useHistory

  return (
    <Box
      width={{ base: "100%", md: "100%" }}
      height="7vh"
      bg={colorMode === "dark" ? "gray.800" : "white"}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={1}
      pl={2}
      pr={4}
      pt={2}
      pb={2}
      borderBottom="1px"
      borderColor="blackAlpha.200"
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton
          icon={<FiArrowLeft />}
          bg="transparent"
          onClick={() => history.goBack()} // Navigate back on click
          aria-label="Back"
          _hover="none"
        />
        <Text ml={0} fontSize="md" mb={0}>
          {selectedProgram?.name || "Program Name"}
        </Text>
      </Box>
      {/* {selectedRow?.executions?.length && (
      <Button
        size="sm"
        bg="black"
        color="white"
        _hover="none"
        onClick={handleGenerateContent}
        isDisabled={!selectedRow || isGenerating}
      >
        {isGenerating ? <Spinner size="sm" /> : "Generate"} 
      </Button>
    )} */}
    </Box>
  );
};

export default GenerationActions;
