import React, { useState, useEffect } from "react";
import {
  Tr,
  Td,
  IconButton,
  Button,
  Spinner,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { FiSettings, FiEye, FiPlay } from "react-icons/fi";
import { DeleteIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import OutreachProfileDropdown from "../../common/OutreachProfileDropdown";
import SenderProfileDropdown from "../../common/SenderProfileDropdown";
import axiosInstance from "../../../helpers/axiosInstance";

const TableRow = ({
  row,
  formatDate,
  onUploadOpen,
  handleOpenSettings,
  confirmDelete,
  selectedProgramId,
  fetchSelectedProgram,
  handleGenerateContent,
  isGenerating,
  generatingRowId,
}) => {
  // console.log("TableRow", row)
  const history = useHistory();
  const [editableLabel, setEditableLabel] = useState(""); // Initialize with empty string
  const [isError, setIsError] = useState(false); // State to track error in label input

  useEffect(() => {
    setEditableLabel(row.label || ""); // Update editableLabel when row changes
  }, [row]);

  const handleOutreachChange = async (value) => {
    await fetchSelectedProgram(selectedProgramId);
  };

  const handleSenderChange = async (value) => {
    await fetchSelectedProgram(selectedProgramId);
  };

  const handleViewDetails = () => {
    history.push(
      `/program/generation/${selectedProgramId}/${row.file_unique_id}`
    );
  };

  const handleLabelChange = (e) => {
    const newText = e.target.value;
    const wordCount = newText.split(/\s+/).filter(Boolean).length;
    if (wordCount <= 3) {
      setEditableLabel(newText);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const saveLabel = async () => {
    if (!isError && editableLabel.trim()) {
      try {
        const payload = { label: editableLabel };
        await axiosInstance.post(
          `/program/set-label/${selectedProgramId}/${row.file_unique_id}`,
          payload
        );
        fetchSelectedProgram(selectedProgramId);
      } catch (error) {
        console.error("Failed to update label:", error);
      }
    }
  };

  return (
    <Tr>
      <Td textAlign="left">{formatDate(row.data_time)}</Td>

      <Td textAlign="left">
        <OutreachProfileDropdown
          selectedValue={row.outreach_profile_id || ""}
          programId={selectedProgramId}
          fileUniqueId={row.file_unique_id}
          onChange={handleOutreachChange}
        />
      </Td>
      <Td textAlign="left">
        <SenderProfileDropdown
          selectedValue={row.sender_profile_id || ""}
          programId={selectedProgramId}
          fileUniqueId={row.file_unique_id}
          onChange={handleSenderChange}
        />
      </Td>
      <Td textAlign="center">
        <Button
          onClick={onUploadOpen}
          bg="#231F1F"
          color="#FFFFFF"
          _hover={{ bg: "#404040" }}
          borderRadius="full"
          size="xs"
          mr={3}
        >
          Add
        </Button>
      </Td>
      <Td textAlign="center">
        <IconButton
          icon={<FiSettings />}
          size="sm"
          aria-label="Settings"
          bg="transparent"
          onClick={() => handleOpenSettings(row)}
        />
      </Td>
      <Td textAlign="center">
        <IconButton
          icon={isGenerating ? <Spinner size="sm" /> : <FiPlay />}
          color="green"
          size="sm"
          aria-label="Run"
          bg="transparent"
          onClick={() => handleGenerateContent()}
          isDisabled={!isGenerating && generatingRowId !== null}
        />
      </Td>
      <Td textAlign="left">{row.filename}</Td>

      <Td textAlign="center">
        <Tooltip
          label="Only 3 words allowed"
          isDisabled={!isError}
          hasArrow
          placement="top"
        >
          <Input
            value={editableLabel}
            onChange={handleLabelChange}
            onBlur={saveLabel}
            size="sm"
            placeholder="Enter label"
            borderRadius="full"
            isInvalid={isError}
          />
        </Tooltip>
      </Td>
      <Td textAlign="center">
        <IconButton
          icon={<FiEye />}
          size="sm"
          aria-label="View Result"
          bg="transparent"
          onClick={handleViewDetails}
          isDisabled={!row.executions || row.executions.length === 0} 
        />
      </Td>
      <Td textAlign="center">
        <IconButton
          icon={<DeleteIcon />}
          size="sm"
          aria-label="Delete"
          bg="transparent"
          onClick={() => confirmDelete(row)}
        />
      </Td>
    </Tr>
  );
};

export default TableRow;
