import React, { useEffect, useState } from "react";
import { Box, Text, VStack, Flex, useToast } from "@chakra-ui/react";
import { Line, Pie } from "react-chartjs-2";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const ChartComponent = ({ selectedProgram }) => {
  const toast = useToast();
  const [insightData, setInsightData] = useState({
    api_cost: 0,
    file_count: 0,
    first_email_counter: 0,
    linkedin_connect_counter: 0,
    second_email_counter: 0,
    linkedin_counter: 0,
    cold_calling: 0,
  });

  useEffect(() => {
    const fetchInsightData = async () => {
      if (selectedProgram) {
        try {
          const response = await axiosInstance.post("/insight/get-insight", {
            program_name: selectedProgram.name,
          });

          if (response.data.success) {
            setInsightData({
              api_cost: parseFloat(response.data.data.api_cost).toFixed(3),
              file_count: response.data.data.file_count,
              first_email_counter: response.data.data["1st_email_counter"],
              linkedin_connect_counter:
                response.data.data.linkedin_connect_counter,
              second_email_counter: response.data.data["2nd_email_counter"],
              linkedin_counter: response.data.data.linkedin_counter,
              cold_calling: response.data.data.cold_calling,
            });
          } else {
            throw new Error(
              response.data.message || "Failed to fetch insight data"
            );
          }
        } catch (error) {
          console.error("Error fetching insight data:", error);
          setInsightData({
            api_cost: 0,
            file_count: 0,
            first_email_counter: 0,
            linkedin_connect_counter: 0,
            second_email_counter: 0,
            linkedin_counter: 0,
            cold_calling: 0,
          });
        }
      }
    };

    fetchInsightData();
  }, [selectedProgram, toast]);

  const lineChartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Reachout",
        data: [30, 20, 27, 18, 23, 34, 44],
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };

  const pieChartData = {
    labels: [
      "1st Email",
      "LinkedIn Connect",
      "2nd Email",
      "LinkedIn",
      "Cold Calling",
    ],
    datasets: [
      {
        label: "# of contacts",
        data: [
          insightData.first_email_counter,
          insightData.linkedin_connect_counter,
          insightData.second_email_counter,
          insightData.linkedin_counter,
          insightData.cold_calling,
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)", // LinkedIn
          "rgba(255, 99, 132, 0.2)", // 1st Email
          "rgba(255, 206, 86, 0.2)", // 2nd Email
          "rgba(75, 192, 192, 0.2)", // LinkedIn Connect
          "rgba(153, 102, 255, 0.2)", // Cold Calling
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)", // LinkedIn
          "rgba(255, 99, 132, 1)", // 1st Email
          "rgba(255, 206, 86, 1)", // 2nd Email
          "rgba(75, 192, 192, 1)", // LinkedIn Connect
          "rgba(153, 102, 255, 1)", // Cold Calling
        ],
        borderWidth: 1,
      },
    ],
  };

  const pieChartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 10,
          },
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Box
      width={{ base: "100%", md: "100%" }}
      height={{ base: "auto", md: "35vh" }}
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      p={4}
    >
      <Box
        borderRadius="lg"
        overflow="hidden"
        p={3}
        textAlign="center"
        alignContent="center"
        w="30%"
        h="100%"
        bg="white"
        boxShadow="md"
      >
        <Text fontSize="sm" fontWeight="semibold" mb={0}>
          Reachout Generation
        </Text>
        <Box height="20vh">
          <Line data={lineChartData} options={{ maintainAspectRatio: false }} />
        </Box>
      </Box>
      <Box
        borderRadius="lg"
        overflow="hidden"
        p={3}
        textAlign="center"
        alignContent="center"
        w="30%"
        h="100%"
        bg="white"
        boxShadow="md"
      >
        <Text fontSize="sm" mb={2} fontWeight="semibold">
          Generation
        </Text>
        <Box height="23vh">
          <Pie data={pieChartData} options={pieChartOptions} />
        </Box>
      </Box>
      <VStack w="30%" h="100%" spacing={0}>
        <Box
          borderRadius="lg"
          overflow="hidden"
          p={4}
          w="100%"
          h="100%"
          bg="white"
          boxShadow="md"
          display="flex"
          flexDirection="column"
        >
          <Text fontSize="sm" fontWeight="semibold" mb={2} textAlign="center">
            Program Files Uploaded
          </Text>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <Text fontSize="50px">{insightData.file_count}</Text>
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
};

export default ChartComponent;

// import React, { useEffect, useState } from "react";
// import { Box, Text, VStack, Flex, useToast } from "@chakra-ui/react";
// import { Line, Pie } from "react-chartjs-2";
// import axiosInstance from "../../../helpers/axiosInstance";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   ArcElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// // Register Chart.js components
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   ArcElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const ChartComponent = ({ selectedProgram }) => {
//   // console.log("in ChartComponent");
//   const toast = useToast();
//   const [insightData, setInsightData] = useState({
//     api_cost: 0,
//     file_count: 0,
//     email_counter: 0,
//     Linkedin_counter: 0,
//     cold_calling: 0,
//   });

//   useEffect(() => {
//     const fetchInsightData = async () => {
//       if (selectedProgram) {
//         try {
//           const response = await axiosInstance.post("/insight/get-insight", {
//             program_name: selectedProgram.name,
//           });

//           if (response.data.success) {
//             setInsightData({
//               api_cost: parseFloat(response.data.data.api_cost).toFixed(3),
//               file_count: response.data.data.file_count,
//               email_counter: response.data.data.email_counter,
//               Linkedin_counter: response.data.data.Linkedin_counter,
//               cold_calling: response.data.data.cold_calling,
//             });
//           } else {
//             throw new Error(
//               response.data.message || "Failed to fetch insight data"
//             );
//           }
//         } catch (error) {
//           console.error("Error fetching insight data:", error);
//           setInsightData({
//             api_cost: 0,
//             file_count: 0,
//             email_counter: 0,
//             Linkedin_counter: 0,
//             cold_calling: 0,
//           });
//         }
//       }
//     };

//     fetchInsightData();
//   }, [selectedProgram, toast]);

//   const lineChartData = {
//     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
//     datasets: [
//       {
//         label: "Reachout",
//         data: [30, 20, 27, 18, 23, 34, 44],
//         fill: false,
//         backgroundColor: "rgb(75, 192, 192)",
//         borderColor: "rgba(75, 192, 192, 0.2)",
//       },
//     ],
//   };

//   const pieChartData = {
//     labels: ["Email", "LinkedIn", "Cold Calling"],
//     datasets: [
//       {
//         label: "# of contacts",
//         data: [
//           insightData.email_counter,
//           insightData.Linkedin_counter,
//           insightData.cold_calling,
//         ],
//         backgroundColor: [
//           "rgba(255, 99, 132, 0.2)",
//           "rgba(54, 162, 235, 0.2)",
//           "rgba(255, 206, 86, 0.2)",
//         ],
//         borderColor: [
//           "rgba(255, 99, 132, 1)",
//           "rgba(54, 162, 235, 1)",
//           "rgba(255, 206, 86, 1)",
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   const pieChartOptions = {
//     plugins: {
//       legend: {
//         display: true,
//         position: "bottom",
//         labels: {
//           usePointStyle: true,
//           pointStyle: "circle",
//           font: {
//             size: 10, // Adjust the size of the legend text if needed
//           },
//           boxWidth: 10, // Set the boxWidth to adjust the size of the circle
//           boxHeight: 10, // Set the boxHeight to adjust the size of the circle
//         },
//       },
//     },
//     maintainAspectRatio: false,
//   };

//   return (
//     <Box
//       width={{ base: "100%", md: "100%" }}
//       height={{ base: "auto", md: "35vh" }}
//       display="flex"
//       alignItems="center"
//       justifyContent="space-around"
//       p={4}
//     >
//       <Box
//         borderRadius="lg"
//         overflow="hidden"
//         p={3}
//         textAlign="center"
//         alignContent="center"
//         w="30%"
//         h="100%"
//         bg="white"
//         boxShadow="md"
//       >
//         <Text fontSize="sm" fontWeight="semibold" mb={0}>
//           Reachout Generation
//         </Text>
//         <Box height="20vh">
//           <Line data={lineChartData} options={{ maintainAspectRatio: false }} />
//         </Box>
//       </Box>
//       <Box
//         borderRadius="lg"
//         overflow="hidden"
//         p={3}
//         textAlign="center"
//         alignContent="center"
//         w="30%"
//         h="100%"
//         bg="white"
//         boxShadow="md"
//       >
//         <Text fontSize="sm" mb={2} fontWeight="semibold">
//           Generation
//         </Text>
//         <Box height="23vh">
//           <Pie data={pieChartData} options={pieChartOptions} />
//         </Box>
//       </Box>
//       <VStack w="30%" h="100%" spacing={0}>
//        {/* <Box
//           borderRadius="lg"
//           overflow="hidden"
//           p={4}
//           textAlign="center"
//           alignContent="center"
//           w="100%"
//           h="45%"
//           bg="white"
//           boxShadow="md"
//         >
//           <Text fontSize="sm" mb={0}>
//             Program Budget Spent
//           </Text>
//           <Text fontSize="lg">${insightData.api_cost}</Text>
//         </Box>*/}
//         <Box
//           borderRadius="lg"
//           overflow="hidden"
//           p={4}
//           w="100%"
//           h="100%"
//           bg="white"
//           boxShadow="md"
//           display="flex"
//           flexDirection="column"
//         >
//           <Text fontSize="sm" fontWeight="semibold" mb={2} textAlign="center">
//             Program Files Uploaded
//           </Text>
//           <Flex flex={1} alignItems="center" justifyContent="center">
//             <Text fontSize="50px">
//               {insightData.file_count}
//             </Text>
//           </Flex>
//         </Box>
//       </VStack>
//     </Box>
//   );
// };

// export default ChartComponent;
