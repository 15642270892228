import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import HomePage from "./components/Home";
import LoginPage from "./components/Login";
import LandingPage from "./components/LandingPage";
import ProtectedRoute from "./components/ProtectedRoute";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AdminPanel from "./components/userRole/AdminPanel";
import ManagerPanel from "./components/userRole/ManagerPanel";
import WebsiteSettings from "./components/websiteSettings/WebsiteSettings";
import OutreachProfiles from "./components/OutreachProfiles/OutreachProfiles";
import SenderInformation from "./components/SenderInformation/SenderInformation";
import Program from "./components/Program/Program";
import GenerationView from "./components/Program/MiddlePannel/Generation/GenerationView";
import ViewExecuteContent from "./components/Program/MiddlePannel/Generation/ViewExecuteContent";
import { UserProvider, useUser } from "./components/userRole/UserContext";
import {
  useGoogleAuth,
  GoogleAuthProvider,
} from "./components/common/google/GoogleAuthContext";

const AppContent = () => {
  const location = useLocation();
  const history = useHistory();
  const { login } = useGoogleAuth();
  const { user, setUser } = useUser();
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const access_token = params.get("access_token");
    const refresh_token = params.get("refresh_token");
    const email = params.get("email");
    const username = params.get("username");
    const role = params.get("role");
    const organization = params.get("organization");
    const moduleAllowed = params.get("moduleAllowed");

    if (access_token) {
      login(access_token);
      const userData = {
        access_token,
        refresh_token,
        email,
        username,
        role: role,
        organization: organization,
        moduleAllowed: moduleAllowed,
      };
      setUser(userData);
      localStorage.setItem("REACT_TOKEN_AUTH_KEY", JSON.stringify(userData));
      localStorage.setItem("email", email);
      localStorage.setItem("username", username);
      localStorage.setItem("organization", organization);
      history.push("/program");
      setIsLoginSuccessful(true);
    }
  }, [window.location.search]);

  return (
    <div className="">
      <Switch>
        <Route path="/get-started">
          <LandingPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/forgot_password">
          <ForgotPassword />
        </Route>
        <Route path="/auth/reset_password/:token">
          <ResetPassword />
        </Route>
        <ProtectedRoute
          path="/admin_panel"
          component={AdminPanel}
          roles={["Admin"]}
        />
        <ProtectedRoute
          path="/manager_panel"
          component={ManagerPanel}
          roles={["Manager"]}
        />
        <ProtectedRoute
          path="/website_settings"
          component={WebsiteSettings}
          roles={["Admin"]}
        />
         <ProtectedRoute
          path="/outreach_profiles"
          component={OutreachProfiles}
          roles={["Admin", "Manager","Employee"]}
        />
          <ProtectedRoute
          path="/sender_information"
          component={SenderInformation}
          roles={["Admin", "Manager","Employee"]}
        />
         <ProtectedRoute
          path="/program/generation/execution/:programId/:fileUniqueId/:executionId"
          component={ViewExecuteContent}
          roles={["Admin", "Manager", "Employee"]}
        />
        <ProtectedRoute
          path="/program/generation/:programId/:fileUniqueId"
          component={GenerationView}
          roles={["Admin", "Manager", "Employee"]}
        />
        <ProtectedRoute
          path="/program"
          component={Program}
          roles={["Admin", "Manager", "Employee"]}
        />
        
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </div>
  );
};

const App = () => {
  return (
    <UserProvider>
      <GoogleAuthProvider>
        <Router>
          <AppContent />
        </Router>
      </GoogleAuthProvider>
    </UserProvider>
  );
};

export default App;