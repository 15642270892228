import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading,
  useToast,
  useDisclosure,
  Button,
  Flex,
} from "@chakra-ui/react";
import axiosInstance from "../../../helpers/axiosInstance";
import UploadModal from "./UploadModal";
import TableRow from "./TableRow";
import DeleteDialog from "./DeleteDialog";
import SettingsModal from "./SettingsModal";

const HistoryTable = ({
  selectedProgram,
  // handleViewDetails,
  data,
  setData,
  fetchSelectedProgram,
  selectedRow,
  setSelectedRow,
}) => {
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();
  const toast = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const onCloseDeleteDialog = () => setIsDeleteDialogOpen(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatingRowId, setGeneratingRowId] = useState(null); // Track generating row
  // const [selectedRow, setSelectedRow] = useState(null);
  const cancelRef = React.useRef();
  const [unsavedManualData, setUnsavedManualData] = useState({});

  useEffect(() => {
    // Load unsaved manual data from local storage on component mount
    const storedData = localStorage.getItem('unsavedManualData');
    if (storedData) {
      setUnsavedManualData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    if (selectedProgram) {
      const fileList = selectedProgram.file_list || [];
      setData(fileList);
    }
  }, [selectedProgram]);

  useEffect(() => {
    // Save unsaved manual data to local storage whenever it changes
    localStorage.setItem('unsavedManualData', JSON.stringify(unsavedManualData));
  }, [unsavedManualData]);

  const handleOpenUpload = (row) => {
    setSelectedRow(row);
    onUploadOpen();
  };

  const handleOpenSettings = (row = null) => {
    setSelectedRow(row);
    onSettingsOpen();
  };

  const handleSaveSettings = (settingsValue) => {
    if (selectedRow) {
      const updatedData = data.map((item) =>
        item.file_unique_id === selectedRow.file_unique_id
          ? { ...item, setting: settingsValue }
          : item
      );
      setData(updatedData);
    }
  };

  const handleDelete = async () => {
    if (rowToDelete) {
      try {
        await axiosInstance.delete(
          `/program/delete-file/${selectedProgram._id}/${rowToDelete.file_unique_id}`
        );
        setData((prevData) =>
          prevData.filter(
            (item) => item.file_unique_id !== rowToDelete.file_unique_id
          )
        );
        fetchSelectedProgram(selectedProgram._id);
        toast({
          title: "File deleted",
          description: `File ${rowToDelete.filename} has been deleted successfully.`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      } catch (error) {
        console.error("Error deleting file:", error);
        let errorMessage = "An error occurred while deleting the file.";
        if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else if (error.message) {
          errorMessage = error.message;
        }
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    onCloseDeleteDialog();
  };

  const confirmDelete = (row) => {
    setRowToDelete(row);
    setIsDeleteDialogOpen(true);
  };

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" ");
    return datePart;
  };

  const handleCreateTemplate = async () => {
    try {
      const response = await axiosInstance.post(
        `/program/create-file-template/${selectedProgram._id}`
      );
      if (response.data.success) {
        toast({
          title: "Template Created",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        fetchSelectedProgram(selectedProgram._id);
      }
    } catch (error) {
      console.error("Error creating template:", error);
      let errorMessage = "An error occurred while creating the template.";
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleGenerateContent = async (row) => {
    try {
      setGeneratingRowId(row.file_unique_id); // Set the generating row ID
      const payload = {
        program_id: selectedProgram._id,
        file_unique_id: row.file_unique_id,
      };
      const response = await axiosInstance.post(
        "/content-generation/generate-content",
        payload
      );

      if (response.status === 200) {
        fetchSelectedProgram(selectedProgram._id);
        toast({
          title: "Content Generation",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error generating content:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while generating content.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setGeneratingRowId(null); // Reset the generating row ID
    }
  };

  return (
    <Box
      width={{ base: "100%", md: "100%" }}
      h="100%"
      bg="white"
      borderRadius="xl"
      p={3}
      pb={1}
      borderColor="blackAlpha.200"
      borderWidth="1px"
    >
      <Flex justify="space-between" align="center" mb={4}>
        <Heading fontWeight="bold" fontSize="lg" ml={1} color="black">
          Program History
        </Heading>
        <Button
          onClick={handleCreateTemplate}
          bg="#231F1F"
          color="#FFFFFF"
          _hover={{ bg: "#404040" }}
          borderRadius="full"
          size="sm"
        >
          Create
        </Button>
      </Flex>
      <Box h="81%" overflowY="auto">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th textAlign="left" fontWeight="bold" width="10%">
                Date
              </Th>
              <Th textAlign="left" fontWeight="bold" width="10%">
                Outreach Profile
              </Th>
              <Th textAlign="left" fontWeight="bold" width="10%">
                From
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Prospect Info
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Config
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Run
              </Th>
              <Th textAlign="left" fontWeight="bold" width="10%">
                Filename
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Label
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                View Runs
              </Th>
              <Th textAlign="center" fontWeight="bold" width="10%">
                Delete
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(selectedProgram.file_list) &&
            selectedProgram.file_list.length > 0 ? (
              selectedProgram.file_list.map((row, index) => (
                <TableRow
                  key={index}
                  row={row}
                  formatDate={formatDate}
                  onUploadOpen={() => handleOpenUpload(row)}
                  handleOpenSettings={handleOpenSettings}
                  confirmDelete={confirmDelete}
                  selectedProgramId={selectedProgram._id} // Pass program ID here
                  fetchSelectedProgram={fetchSelectedProgram}
                  handleGenerateContent={() => handleGenerateContent(row)} // Pass row to handler
                  isGenerating={generatingRowId === row.file_unique_id} // Check if this row is generating
                  generatingRowId={generatingRowId}
                />
              ))
            ) : (
              <Tr>
                <Td colSpan="10" textAlign="center" fontWeight="semibold">
                  No file uploaded
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={onCloseDeleteDialog}
        onDelete={handleDelete}
        cancelRef={cancelRef}
      />

      <UploadModal
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        selectedProgram={selectedProgram}
        fetchSelectedProgram={fetchSelectedProgram}
        fileUniqueId={selectedRow?.file_unique_id}
        unsavedManualData={unsavedManualData}
        setUnsavedManualData={setUnsavedManualData}
      />

      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={onSettingsClose}
        selectedRow={selectedRow}
        onSave={handleSaveSettings}
        selectedProgram={selectedProgram}
        fetchSelectedProgram={fetchSelectedProgram}
      />
    </Box>
  );
};

export default HistoryTable;
