import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import axiosInstance from "../../helpers/axiosInstance";

const AddProfileModal = ({ isOpen, onClose, profileData, onSave }) => {
  const [profileName, setProfileName] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [keywords, setKeywords] = useState([]);
  const toast = useToast();

  // Reset state when profileData or isOpen changes (i.e., when the modal opens)
  useEffect(() => {
    if (isOpen) {
      if (profileData) {
        // Edit mode: populate the state with profileData
        setProfileName(profileData.outreach_profile_name || "");
        setAboutUs(profileData.sender_company_product_information || "");
        setKeywords(
          profileData.in_the_news_search_term
            ? profileData.in_the_news_search_term.map((term) => ({
                value: term,
                label: term,
              }))
            : []
        );
      } else {
        // Add mode: clear the state
        setProfileName("");
        setAboutUs("");
        setKeywords([]);
      }
    }
  }, [profileData, isOpen]);

  const handleSaveProfile = async () => {
    try {
      const payload = {
        outreach_profile_name: profileName,
        sender_company_product_information: aboutUs,
        in_the_news_search_term: keywords.map((tag) => tag.value),
      };

      let response;
      if (profileData && profileData._id) {
        // Update profile
        response = await axiosInstance.put(`/profile/${profileData._id}`, payload);
      } else {
        // Add new profile
        response = await axiosInstance.post("/profile/", payload);
      }

      if (response.status === 200) {
        toast({
          title: profileData ? "Profile Updated" : "Profile Created",
          description: response.data.message, // Use message from backend
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });

        onSave();
        onClose();
      }
    } catch (error) {
      console.error("Error saving profile:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving the profile.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent h="78vh">
        <ModalHeader>{profileData ? "Edit Profile" : "Add New Profile"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <FormControl>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
              Profile Name
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Enter profile name
            </Text>
            <Input
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
              placeholder="Enter profile name"
              fontSize="sm"
              borderRadius="xl"
            />
          </FormControl>

          <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
              About Us
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Tell us about yourself.
            </Text>
            <Textarea
              value={aboutUs}
              onChange={(e) => setAboutUs(e.target.value)}
              placeholder="Write here..."
              fontSize="sm"
              borderRadius="xl"
              h="10vh"
            />
          </FormControl>

          <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
              Keywords
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Add keywords.
            </Text>
            <CreatableSelect
              isMulti
              onChange={setKeywords}
              value={keywords}
              placeholder="Enter keywords..."
              borderRadius="xl"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#231F1F"
            color="#FFFFFF"
            _hover={{ bg: "#404040" }}
            borderRadius="full"
            size="sm"
            onClick={handleSaveProfile}
          >
            {profileData ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProfileModal;
