import React from "react";
import { Box, Flex, Text, IconButton, Input } from "@chakra-ui/react";
import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

const CreateButton = ({
  showInputField,
  setShowInputField,
  currentProgram,
  setCurrentProgram,
  handleAddProgram,
  width,
  color,
}) => {
  return (
    <Box h="6vh" align="center" justifyContent="center">
      {!showInputField && (
        <Box
          pl={4}
          pr={4}
          onClick={() => setShowInputField(!showInputField)}
          cursor="pointer"
        >
          <Flex
            h="100%"
            w={width}
            alignItems="center"
            justifyContent="center" // Add this line to center horizontally
            mb={0}
            bg="#FEA130"
            borderRadius="md"
          >
            <Text
              fontWeight="semibold"
              color="white"
              mb={0}
              fontSize="sm"
              p={2}
              textAlign="center" // Ensure text is centered within the Text component
            >
              Create Program
            </Text>
            {/* <IconButton
              variant="ghost"
              aria-label="Add Program"
              icon={<AddIcon color="white" />}
              onClick={() => setShowInputField(!showInputField)}
              size="xs"
              ml={4}
            /> */}
          </Flex>
        </Box>
      )}
      {showInputField && (
        <Box pl={4} pb={2} pr={4}>
          <Flex h="100%" align="center" mb={0} pl={0}>
            <Input
              placeholder="Enter Program"
              size="sm"
              mr={2}
              value={currentProgram}
              onChange={(e) => setCurrentProgram(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") handleAddProgram();
              }}
              color={color}
              w="70%"
              borderRadius="lg"
            />
            <IconButton
              aria-label="Add Program"
              icon={<CheckIcon color="white" />}
              onClick={handleAddProgram}
              size="sm"
              bg="#FEA130"
              _hover={{ bg: "#FEA130" }}
              w="15%"
              borderRadius="lg"
            />
            <IconButton
              aria-label="Cancel"
              icon={<CloseIcon color="white" />}
              onClick={() => setShowInputField(false)}
              size="sm"
              bg="red.500"
              _hover={{ bg: "red.500" }}
              w="15%"
              borderRadius="lg"
              ml={2}
            />
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default CreateButton;
