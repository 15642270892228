import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  Link,
  Flex,
  Button,
  Container,
  Heading,
  Stack,
  Divider,
  VStack,
  Text,
  Image,
} from "@chakra-ui/react";
import logo from "../assets/images/alveelogo.png";
import "./../styles/styles.css";

const content = [
  {
    heading: "Welcome to Our Platform!",
    text: "explore our features and join today.",
  },
  { heading: "Join Our Community", text: "connect, learn, and grow with us." },
];

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setAnimationTrigger((prev) => !prev); // Toggle the trigger
        return (prevIndex + 1) % content.length;
      });
    }, 3000); // Change content every 4 seconds
    return () => clearInterval(timer);
  }, []);

  return (
    <Flex h="100vh">
      <Box w="60%" bg="#FAC900" pt={5} pl={5} pb={40}>
        <Image src={logo} alt="Logo" width="70px" height="auto" />
        <Container w="90%" ml={0} pt={40}>
          <Heading
            color="#231F1F"
            textAlign="left"
            fontFamily="'ui', sans-serif"
          >
            {content[currentIndex].heading}
          </Heading>
          <Text
            color="#231F1F"
            className={animationTrigger ? "typewriter" : "typewriter alternate"}
            key={currentIndex}
            textAlign="left"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
          >
            {content[currentIndex].text}
          </Text>
        </Container>
      </Box>

      <Box w="40%" bg="#FFFFFF" p={10} pt="60" pb="60">
        <Container centerContent>
          <VStack spacing={0}>
            <Heading
              as="h1"
              size="xl"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#3A4135"
              fontSize="32"
              textAlign="center"
              mb={0}
            >
              Get Started
            </Heading>
            <Stack direction="row" spacing={2} align="center">
              <Button
                w="214px"
                bg="#231F1F"
                color="#FFFFFF"
                fontFamily="'Roboto', sans-serif"
                fontWeight="medium"
                _hover={{ bg: "#404040" }}
                onClick={() => (window.location.href = "/login")}
              >
                Login
              </Button>
              <Divider
                orientation="vertical"
                height="50px"
                colorScheme="black"
              />
              <Button
                w="214px"
                bg="#231F1F"
                color="#FFFFFF"
                fontFamily="'Roboto', sans-serif"
                fontWeight="medium"
                _hover={{ bg: "#404040" }}
                onClick={() => (window.location.href = "/login")}
              >
                Subscribe
              </Button>
            </Stack>
          </VStack>
        </Container>
        <Box alignContent="center" justifyContent="center" p={5} pb={0} pt={40}>
          <HStack alignContent="center" justifyContent="center">
            <Link
              fontSize="12"
              href="/policies/terms-of-use"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
              // mr={4}
            >
              Terms of Use
            </Link>
            <Divider
              alignSelf="center"
              orientation="vertical"
              height="12px" // Adjust height according to your needs
              borderColor="black" // Ensure this is visible against your background
              borderWidth="0.5px"
            />
            <Link
              fontSize="12"
              href="/policies/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default LandingPage;

// import React, { useState, useEffect } from "react";
// import { useTranslation } from 'react-i18next';  // Import the hook
// import {
//   Box, HStack, Link, Flex, Button, Container,
//   Heading, Stack, Divider, VStack, Text, Image
// } from "@chakra-ui/react";
// import logo from "../assets/images/logo1.png";
// import LanguageSwitcher from "./LanguageSwitcher";
// import "./../styles/styles.css";

// const LandingPage = () => {
//   const { t } = useTranslation();  // Initialize the translation function
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [animationTrigger, setAnimationTrigger] = useState(false);

//   // Define the content array inside the component
//   const content = [
//     {
//       heading: "welcomeHeading",
//       text: "welcomeText",
//     },
//     {
//       heading: "joinHeading",
//       text: "joinText"
//     },
//   ];

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) => {
//         setAnimationTrigger((prev) => !prev); // Toggle the trigger
//         return (prevIndex + 1) % content.length;
//       });
//     }, 3000); // Change content every 3 seconds
//     return () => clearInterval(timer);
//   }, []);

//   return (
//     <Flex h="100vh">
//       <Box w="60%" bg="#004A45" pt={10} pl={5} pb={40}>
//         <Image src={logo} alt="Logo" width="70px" height="auto" />
//         <Container maxW="100%" width="full" pt={40}>
//           <Heading
//             color="#02FB45"
//             textAlign="left"
//             fontFamily="'ui', sans-serif"
//           >
//             {t(content[currentIndex].heading)}
//           </Heading>
//           <Text
//             color="#02FB45"
//             className={animationTrigger ? "typewriter" : "typewriter alternate"}
//             key={currentIndex}
//             textAlign="left"
//             fontFamily="'ui', sans-serif"
//             fontWeight="bold"
//           >
//             {t(content[currentIndex].text)}
//           </Text>
//         </Container>
//       </Box>

//       <Box w="40%" bg="#FFFFFF" p={10} pt="60" pb="60">
//         {/* <LanguageSwitcher /> */}
//         <Container centerContent>
//           <VStack spacing={0}>
//             <Heading
//               as="h1"
//               size="xl"
//               fontFamily="'Manrope', sans-serif"
//               fontWeight="medium"
//               color="#3A4135"
//               fontSize="32"
//               textAlign="center"
//               mb={0}
//             >
//               Get Started
//             </Heading>
//             <Stack direction="row" spacing={2} align="center">
//               <Button
//                 w="214px"
//                 bg="#955514"
//                 color="#FFFFFF"
//                 fontFamily="'Roboto', sans-serif"
//                 fontWeight="medium"
//                 _hover={{ bg: "#804000" }}
//                 onClick={() => (window.location.href = "/login")}
//               >
//                 Login
//               </Button>
//               <Divider
//                 orientation="vertical"
//                 height="50px"
//                 colorScheme="black"
//               />
//               <Button
//                 w="214px"
//                 bg="#955514"
//                 color="#FFFFFF"
//                 fontFamily="'Roboto', sans-serif"
//                 fontWeight="medium"
//                 _hover={{ bg: "#804000" }}
//                 onClick={() => (window.location.href = "/signup")}
//               >
//                 Subscribe
//               </Button>
//             </Stack>
//           </VStack>
//         </Container>
//         <Box alignContent="center" justifyContent="center" p={5} pb={0} pt={40}>
//           <HStack alignContent="center" justifyContent="center">
//             <Link
//               fontSize="12"
//               href="/policies/terms-of-use"
//               fontFamily="'Manrope', sans-serif"
//               fontWeight="medium"
//               color="#955514"
//               // mr={4}
//             >
//               Terms of Use
//             </Link>
//             <Divider
//               alignSelf="center"
//               orientation="vertical"
//               height="12px" // Adjust height according to your needs
//               borderColor="black" // Ensure this is visible against your background
//               borderWidth="0.5px"
//             />
//             <Link
//               fontSize="12"
//               href="/policies/privacy-policy"
//               fontFamily="'Manrope', sans-serif"
//               fontWeight="medium"
//               color="#955514"
//             >
//               Privacy Policy
//             </Link>
//           </HStack>
//         </Box>
//       </Box>
//     </Flex>
//   );
// };

// export default LandingPage;
