import React, { useState, useEffect } from "react";
import { Select, useToast } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";

function OutreachProfileDropdown({ selectedValue, onChange = () => {}, programId, fileUniqueId }) {
  const [profiles, setProfiles] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      const response = await axiosInstance.get("/profile/");
      if (response.status === 200 && response.data.success) {
        setProfiles(response.data.data.profiles || []);
      }
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  };

  const handleChange = async (value) => {
    const selectedProfile = profiles.find(profile => profile._id === value);
    if (!selectedProfile) return;

    const payload = {
      outreach_profile_id: selectedProfile._id,
      outreach_profile_name: selectedProfile.outreach_profile_name,
    };

    try {
      const response = await axiosInstance.post(
        `/program/set-outreach-profile/${programId}/${fileUniqueId}`,
        payload
      );
      if (response.status === 200 && response.data.success) {
        toast({
          title: "Profile Updated",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
      onChange(value); // Call onChange to trigger fetchSelectedProgram
    } catch (error) {
      console.error("Error updating profile:", error);
      toast({
        title: "Error",
        description: "Failed to update the outreach profile.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Select
      value={selectedValue}
      onChange={(e) => handleChange(e.target.value)}
      placeholder="Select"
      size="sm"
      borderRadius="full"
    >
      {profiles.map((profile) => (
        <option key={profile._id} value={profile._id}>
          {profile.outreach_profile_name}
        </option>
      ))}
    </Select>
  );
}

export default OutreachProfileDropdown;
