import React, { useState } from "react";
import { HStack, Button, Select } from "@chakra-ui/react";

const ViewDetailsPagination = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
  fileCount,
  setFileCount,
}) => {
  const handleFileCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setFileCount(value);
  };

  return (
    <HStack spacing={4} p={4} justifyContent="right" mr={4} mt={0} h="5vh">
      <Button
        onClick={handlePrevPage}
        isDisabled={currentPage === 1}
        bg="#231F1F"
        color="#FFFFFF"
        _hover={{ bg: "#404040" }}
        borderRadius="full"
        size="xs"
        fontSize="xs"
      >
        Previous
      </Button>
      {/* <Text fontSize="xs" mb={0} color="black">
        Page {currentPage} of {totalPages}
      </Text> */}
      <Select
        value={fileCount}
        onChange={handleFileCountChange}
        size="xs"
        width="70px"
        fontSize="xs"
        borderRadius="full"
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
      </Select>
      <Button
        onClick={handleNextPage}
        isDisabled={currentPage === totalPages}
        bg="#231F1F"
        color="#FFFFFF"
        _hover={{ bg: "#404040" }}
        borderRadius="full"
        size="xs"
        fontSize="xs"
      >
        Next
      </Button>
    </HStack>
  );
};

export default ViewDetailsPagination;


// import React, { useState } from "react";
// import { HStack, Button, Text, Input } from "@chakra-ui/react";

// const ViewDetailsPagination = ({
//   currentPage,
//   totalPages,
//   handlePrevPage,
//   handleNextPage,
//   fileCount,
//   setFileCount,
// }) => {
//   const [inputValue, setInputValue] = useState(fileCount);

//   const handleFileCountChange = (e) => {
//     const value = e.target.value;
//     setInputValue(value);

//     // Allow empty value to support backspace
//     if (value === "") {
//       setFileCount("");
//       return;
//     }

//     const parsedValue = parseInt(value, 10);
//     if (!isNaN(parsedValue) && parsedValue > 0) {
//       setFileCount(parsedValue);
//     }
//   };

//   const handleBlur = () => {
//     if (inputValue === "") {
//       setInputValue(fileCount); // Reset to previous valid value if left empty
//     }
//   };

//   return (
//     <HStack spacing={4} p={4} justifyContent="right" mr={4} mt={0} h="5vh">
//       <Button
//         onClick={handlePrevPage}
//         isDisabled={currentPage === 1}
//         bg="#231F1F"
//         color="#FFFFFF"
//         _hover={{ bg: "#404040" }}
//         borderRadius="full"
//         size="xs"
//         fontSize="xs"
//       >
//         Previous
//       </Button>
//       {/* <Text fontSize="xs" mb={0} color="black">
//         Page {currentPage} of {totalPages}
//       </Text> */}
//       <Input
//         value={inputValue}
//         onChange={handleFileCountChange}
//         onBlur={handleBlur}
//         size="xs"
//         width="50px"
//         fontSize="xs"
//         type="number"
//         min="1"
//         borderRadius="full"
//       />
//       <Button
//         onClick={handleNextPage}
//         isDisabled={currentPage === totalPages}
//         bg="#231F1F"
//         color="#FFFFFF"
//         _hover={{ bg: "#404040" }}
//         borderRadius="full"
//         size="xs"
//         fontSize="xs"
//       >
//         Next
//       </Button>
//     </HStack>
//   );
// };

// export default ViewDetailsPagination;
