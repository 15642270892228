import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  useColorMode,
  useColorModeValue,
  useToast,
  Image,
  Divider,
  VStack,
  SlideFade,
  Slide,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import GoogleLoginButton from "./common/google/GoogleLoginButton";
// import SignInWithApple from "./common/apple/SignInWithApple";
import { login } from "../auth";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useUser } from "./userRole/UserContext";
import logo from "../assets/images/alveelogo.png";
import "./../styles/styles.css";
import axios from "axios";

const content = [
  {
    heading: "AI Custom Outreach",
    text: "Revolutionizing Personalized Outreach with Cutting-Edge AI Solutions.",
  },
  {
    heading: "AI Custom Outreach",
    text: "Generate Personalized Emails for Your Leads, Instantly.",
  },
  {
    heading: "AI Custom Outreach",
    text: "Create Tailored LinkedIn Messages for Enhanced Engagement.",
  },
  {
    heading: "AI Custom Outreach",
    text: "Design Personalized Calling Scripts for Effective Conversations.",
  },
];

const LoginPage = () => {
  // const { register, handleSubmit, formState: { errors } } = useForm();
  //console.log("In LoginPage function");
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const { user, setUser } = useUser();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1); // Step 1 for email, Step 2 for password

  const handleNext = () => {
    const email = getValues("email");
    if (email) {
      setStep(2); // Move to password step
    } else {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  function getDeviceDetails() {
    const ua = navigator.userAgent;

    // Detecting the browser
    let browser;
    if (/chrome|chromium|crios/i.test(ua)) {
      browser = "Chrome";
    } else if (/firefox|fxios/i.test(ua)) {
      browser = "Firefox";
    } else if (/safari/i.test(ua)) {
      browser = "Safari";
    } else if (/edg/i.test(ua)) {
      browser = "Edge";
    } else if (/opr\//i.test(ua)) {
      browser = "Opera";
    } else if (/msie|trident/i.test(ua)) {
      browser = "IE";
    } else {
      browser = "Unknown Browser";
    }

    // Detecting the platform
    let os;
    if (/android/i.test(ua)) {
      os = "Android";
    } else if (/iphone|ipad|ipod/i.test(ua)) {
      os = "iOS";
    } else if (/win/i.test(ua)) {
      os = "Windows";
    } else if (/mac/i.test(ua)) {
      os = "MacOS";
    } else if (/linux/i.test(ua)) {
      os = "Linux";
    } else {
      os = "Unknown OS";
    }

    return {
      full_user_agent: navigator.userAgent,
      simplified_user_agent: `${os} ${browser}`,
      loginDate: new Date().toISOString().slice(0, 10), // ISO 8601 format for YYYY-MM-DD
    };
  }

  const onSubmit = async (formData) => {
    const deviceDetails = getDeviceDetails();

    const loginPayload = {
      ...formData,
      device: deviceDetails,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginPayload),
    };

    try {
      const response = await fetch("/auth/login", requestOptions);

      const data = await response.json();

      if (response.status === 200) {
        login(data.data); // This will store the access_token and refresh_token

        localStorage.setItem("email", data.data.email);
        localStorage.setItem("username", data.data.username);
        localStorage.setItem("organization", data.data.organization);
        const role = data.data.role;
        const organization = data.data.organization;

        setUser({
          role,
          organization,
        });
        //console.log("user data", user);
        setIsLoginSuccessful(true);
      } else {
        //console.log("In Login failed page");
        setErrorMessage(data.message || "Login failed");
        toast({
          title: "Login failed",
          description: "Your cridentials are invalid. Please enter again",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setErrorMessage("Failed to connect to the server");
    }
    reset();
  };

  useEffect(() => {
    if (user && isLoginSuccessful) {
      history.push("/program");
      setIsLoginSuccessful(false); // Reset the flag after redirecting
    }
  }, [user, isLoginSuccessful]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setAnimationTrigger((prev) => !prev); // Toggle the trigger
        return (prevIndex + 1) % content.length;
      });
    }, 12000); // Change content every 12 seconds
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const textElement = document.querySelector('.typewriter');
    if (textElement) {
      const timer = setTimeout(() => {
        textElement.classList.add('wrapText');
      }, 10000); // Add wrapText class after 10 seconds
      return () => clearTimeout(timer);
    }
  }, [currentIndex, animationTrigger]);

  return (
    <Flex h="100vh" overflow="hidden">
      {/* Left Side*/}
      <Box w="60%" bg="#FAC900" pt={5} pl={5} pb={40}>
        <Image src={logo} alt="Logo" width="70px" height="auto" />
        <Container w="100%" ml={0} pt={40}>
          <Heading color="#231F1F" textAlign="left" fontFamily="'ui', sans-serif">
            {content[currentIndex].heading}
          </Heading>
          <Text
            color="#231F1F"
            className={`typewriter ${animationTrigger ? 'alternate' : ''}`}
            key={currentIndex}
            textAlign="left"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
            fontSize="lg"
          >
            {content[currentIndex].text}
          </Text>
        </Container>
      </Box>
      
      {/* Right Side with Login Form */}
      <Box w="40%" bg="white" p={10} pt="30" pb="30" alignContent="center">
        <Container>
          <Stack spacing={4}>
            <Heading
              as="h1"
              size="xl"
              textAlign="left"
              mb={4}
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#3A4135"
            >
              Welcome back
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && (
                <FormControl isInvalid={errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Box position="relative" height="6vh">
                    {/*<Slide in={step === 1} direction="right" style={{ position: 'absolute', width: '100%', top: 0 }}> */}
                    <Input
                      id="email"
                      type="email"
                      placeholder="Enter your email"
                      {...register("email", {
                        required: "Email is required",
                      })}
                    />
                    {/* </Slide>*/}
                  </Box>
                  <Button
                    mt={4}
                    onClick={handleNext}
                    colorScheme="blue"
                    type="submit"
                    w="100%"
                    bg="#231F1F"
                    color="#FFFFFF"
                    fontFamily="'Roboto', sans-serif"
                    fontWeight="medium"
                    _hover={{ bg: "#404040" }}
                  >
                    Next
                  </Button>
                  {/* <Text
                    mt={2}
                    mb={0}
                    fontSize="small"
                    fontFamily="'Manrope', sans-serif"
                    fontWeight="medium"
                    color="#3A4135"
                  >
                    Don't have an account?{" "}
                    <Link
                      fontFamily="'Manrope', sans-serif"
                      fontWeight="medium"
                      color="#004A45"
                      href="/signup"
                    >
                      Sign up
                    </Link>
                  </Text> */}
                </FormControl>
              )}

              {step === 2 && (
                <>
                  <FormControl isInvalid={errors.password}>
                    <FormLabel>Password</FormLabel>
                    <Box position="relative" height="6vh">
                      {" "}
                      {/* Adjust height based on content */}
                      <Slide
                        in={step === 2}
                        direction="right"
                        style={{ position: "absolute", width: "100%", top: 0 }}
                      >
                        <InputGroup size="md">
                          <Input
                            pr="4"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            {...register("password", {
                              required: "Password is required",
                            })}
                          />
                          <InputRightElement width="4.5rem">
                            <IconButton
                              h="1.75rem"
                              size="sm"
                              onClick={() => setShowPassword(!showPassword)}
                              icon={showPassword ? <HiEyeOff /> : <HiEye />}
                              aria-label="Toggle password visibility"
                            />
                          </InputRightElement>
                        </InputGroup>
                      </Slide>
                    </Box>
                  </FormControl>
                  <Button
                    mt={4}
                    colorScheme="blue"
                    type="submit"
                    w="100%"
                    bg="#231F1F"
                    color="#FFFFFF"
                    fontFamily="'Roboto', sans-serif"
                    fontWeight="medium"
                    _hover={{ bg: "#404040" }}
                  >
                    Login
                  </Button>

                  <Link
                    href="/forgot_password"
                    fontFamily="'Manrope', sans-serif"
                    fontWeight="medium"
                    color="#3A4135"
                    fontSize="small"
                  >
                    Forgot Password?
                  </Link>
                </>
              )}
            </form>
            {/* <Flex align="center">
              <Divider p={0} mb="0" mt="0" />
              <Text
                textStyle="sm"
                fontFamily="'Manrope', sans-serif"
                fontWeight="medium"
                color="#3A4135"
                mb="0"
                mt="0"
                mr="1"
                ml="1"
                textAlign="center"
              >
                or
              </Text>
              <Divider p={0} mb="0" mt="0" />
            </Flex> */}
            {/* <GoogleLoginButton /> */}
            {/* <SignInWithApple /> */}
          </Stack>
        </Container>
        <Box alignContent="center" justifyContent="center" p={5} pb={0} pt={10}>
          <HStack alignContent="center" justifyContent="center">
            <Link
              fontSize="12"
              href="/policies/terms-of-use"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
            >
              Terms of Use
            </Link>
            <Divider
              alignSelf="center"
              orientation="vertical"
              height="12px"
              borderColor="black"
              borderWidth="0.5px"
            />
            <Link
              fontSize="12"
              href="/policies/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default LoginPage;

// import React, {
//   forwardRef,
//   useRef,
//   useState,
//   useEffect,
//   useContext,
// } from "react";
// import { useForm } from "react-hook-form";
// import { login } from "../auth";
// import { useHistory } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Checkbox,
//   Container,
//   FormControl,
//   FormLabel,
//   Heading,
//   HStack,
//   Input,
//   Link,
//   Stack,
//   Text,
//   IconButton,
//   InputGroup,
//   InputRightElement,
//   useDisclosure,
//   useMergeRefs,
//   useColorMode,
//   useColorModeValue,
//   useToast,
// } from "@chakra-ui/react";
// import { HiEye, HiEyeOff } from "react-icons/hi";
// import { Logo } from "./Logo";
// import "../styles/login.css";
// import { useUser } from "./userRole/UserContext";

// const LoginPage = () => {
//   //console.log("In LoginPage function");
//   const toast = useToast();
//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { errors },
//   } = useForm();
//   const { user, setUser } = useUser();
//   const { colorMode } = useColorMode();
//   const history = useHistory();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

//   const loginUser = async (data) => {
//     //console.log("In Login function");
//     //data.preventDefault(); // Prevents the default form submission behavior

//     const requestOptions = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     };

//     // API call to your backend
//     try {
//       const response = await fetch("/auth/login", requestOptions);

//       const data = await response.json();

//       if (response.status === 200) {
//         login(data.data); // This will store the access_token and refresh_token
//         console.log("response", data.data);
//         // history.push("/");

//         localStorage.setItem("email", data.data.email);
//         localStorage.setItem("username", data.data.username);
//         //console.log("data.role", data.role);
//         //console.log("data.organization", data.organization);
//         const role = data.data.role;
//         const organization = data.data.organization;
//         const moduleAccess = data.data.moduleAccess;
//         //console.log("role",role);
//         //console.log("organization",organization);
//         //console.log("moduleAccess",data.moduleAccess);
//         setUser({
//           role,
//           organization,
//           moduleAccess,
//         });
//         history.push("/");
//         //console.log("user data", user);
//         setIsLoginSuccessful(true);
//       } else {
//         //console.log("In Login failed page");
//         setErrorMessage(data.message || "Login failed");
//         toast({
//           title: "Login failed",
//           description: "Your cridentials are invalid. Please enter again",
//           status: "error",
//           duration: 5000,
//           isClosable: true,
//         });
//       }
//     } catch (error) {
//       setErrorMessage("Failed to connect to the server");
//     }
//     reset();
//   };

//   useEffect(() => {
//     if (user && isLoginSuccessful) {
//         history.push("/");
//         setIsLoginSuccessful(false); // Reset the flag after redirecting
//     }
// }, [user, isLoginSuccessful]); // Include the new flag in the dependency array

//   useEffect(() => {
//     document.body.style.overflow = "hidden";
//     return () => {
//       document.body.style.overflow = "unset";
//     };
//   }, []);

//   //Password Field code
//   const PasswordField = forwardRef((props, ref) => {
//     const { isOpen, onToggle } = useDisclosure();
//     const inputRef = useRef(null);
//     const mergeRef = useMergeRefs(inputRef, ref);
//     const onClickReveal = () => {
//       onToggle();
//       if (inputRef.current) {
//         inputRef.current.focus({
//           preventScroll: true,
//         });
//       }
//     };
//     return (
//       <FormControl>
//         <FormLabel htmlFor="password">Password</FormLabel>
//         <InputGroup>
//           <InputRightElement>
//             <IconButton
//               variant="text"
//               aria-label={isOpen ? "Mask password" : "Reveal password"}
//               icon={isOpen ? <HiEyeOff /> : <HiEye />}
//               onClick={onClickReveal}
//             />
//           </InputRightElement>
//           <Input
//             id="password"
//             ref={mergeRef}
//             name="password"
//             type={isOpen ? "text" : "password"}
//             autoComplete="current-password"
//             required
//             {...props}
//             placeholder="Your password"
//             {...register("password", { required: true, minLength: 8 })}
//           />
//         </InputGroup>
//         {errors.password && (
//           <p style={{ color: "red" }}>
//             <small>Password is required</small>
//           </p>
//         )}
//         {errors.password?.type === "maxLength" && (
//           <p style={{ color: "red" }}>
//             <small>Password should be more than 8 characters</small>
//           </p>
//         )}
//       </FormControl>
//     );
//   });
//   PasswordField.displayName = "PasswordField";

//   // Function to determine the boxShadow based on colorMode and screen size
//   const boxShadow = () => ({
//     base: "none",
//     sm:
//       colorMode === "light"
//         ? "0px 4px 6px -1px rgba(0, 0, 0, 0.15)"
//         : "0px 4px 6px -1px rgba(255, 255, 255, 0.2)",
//   });

//   const bgColor = useColorModeValue("white", "#171923");

//   useEffect(() => {
//     document.body.style.backgroundColor = bgColor;
//     document.body.style.overflow = "hidden";

//     return () => {
//       document.body.style.backgroundColor = ""; // Reset to default
//       document.body.style.overflow = "unset";
//     };
//   }, [bgColor]);
//   return (
//     <Container
//       maxW="lg"
//       py={{
//         base: "10",
//         md: "20",
//       }}
//       px={{
//         base: "0",
//         sm: "8",
//       }}
//       bg={colorMode === "light" ? "white" : "#171923"}
//     >
//       <Stack spacing="4">
//         <Stack spacing="6">
//           <Logo />
//           <Stack
//             spacing={{
//               base: "2",
//               md: "3",
//             }}
//             textAlign="center"
//           >
//             <Heading
//               size={{
//                 base: "xs",
//                 md: "30px",
//               }}
//             >
//               Log in to your account
//             </Heading>
//             {/* <Text color="fg.muted">
//               Don't have an account? <Link href="/signup" color="#2B6CB0">Sign up</Link>
//             </Text> */}
//           </Stack>
//         </Stack>
//         <Box
//           py={{
//             base: "0",
//             sm: "8",
//           }}
//           px={{
//             base: "4",
//             sm: "10",
//           }}
//           bg={{
//             base: "transparent",
//             sm: "bg.surface",
//           }}
//           // boxShadow={{
//           //   base: "none",
//           //   sm: "md",
//           // }}
//           boxShadow={boxShadow()}
//           borderRadius={{
//             base: "none",
//             sm: "xl",
//           }}
//         >
//           <Stack spacing="6">
//             <Stack spacing="5">
//               <FormControl>
//                 <FormLabel htmlFor="email">Email</FormLabel>
//                 <Input
//                   id="email"
//                   type="email"
//                   placeholder="Your email"
//                   {...register("email", { required: true, maxLength: 30 })}
//                 />
//                 {errors.email && (
//                   <p style={{ color: "red" }}>
//                     <small>email is required</small>
//                   </p>
//                 )}
//                 {errors.email?.type === "maxLength" && (
//                   <p style={{ color: "red" }}>
//                     <small>email should be 30 characters</small>
//                   </p>
//                 )}
//               </FormControl>
//               <PasswordField />
//             </Stack>
//             <HStack justify="space-between">
//               <Checkbox defaultChecked>Remember me</Checkbox>
//               <Button variant="text" size="sm">
//                 <Link href="/forgot_password" color="#2B6CB0">
//                   Forgot password?
//                 </Link>
//               </Button>
//             </HStack>
//             <Stack spacing="6">
//               <Button onClick={handleSubmit(loginUser)}>Sign in</Button>
//               {/* <HStack>
//                 <Divider />
//                 <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted" mb="2" mt="2">
//                   or continue with
//                 </Text>
//                 <Divider />
//               </HStack>
//               <AuthButtonGroup /> */}
//             </Stack>
//           </Stack>
//         </Box>
//       </Stack>
//     </Container>
//   );
// };
// export default LoginPage;
