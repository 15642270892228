import React, { useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
} from "@chakra-ui/react";
import { FiCopy, FiCheck } from "react-icons/fi";
import ReactMarkdown from "react-markdown";
import { renderToStaticMarkup } from "react-dom/server";
import { htmlToText } from "html-to-text";

const InfoModal = ({ isOpen, onClose, title, content }) => {
  const [copied, setCopied] = useState(false);
  const contentRef = useRef(null);

  const handleCopy = async () => {
    if (contentRef.current) {
      const html = contentRef.current.innerHTML;
      const text = htmlToText(html, {
        wordwrap: 130,
      });

      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([html], { type: "text/html" }),
            "text/plain": new Blob([text], { type: "text/plain" }),
          }),
        ]);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent h="70vh">
        <ModalHeader mr={4}>{title}</ModalHeader>
        <ModalCloseButton borderRadius="full" />
        <ModalBody overflowY="auto" ref={contentRef}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              onClick={handleCopy}
              leftIcon={copied ? <FiCheck /> : <FiCopy />}
              bg="transparent"
            >
              {/* {copied ? "Copied" : "Copy"} */}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InfoModal;
