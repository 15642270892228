import React, { useState } from "react";
import { HStack, Button, Select } from "@chakra-ui/react";

const Pagination = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
  fileCount,
  setFileCount,
}) => {
  const handleFileCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setFileCount(value);
  };

  return (
    <HStack spacing={4} p={4} justifyContent="right" mr={4} mt={0} h="5vh">
      <Button
        onClick={handlePrevPage}
        isDisabled={currentPage === 1}
        bg="#231F1F"
        color="#FFFFFF"
        _hover={{ bg: "#404040" }}
        borderRadius="full"
        size="xs"
        fontSize="xs"
      >
        Previous
      </Button>
      {/* <Text fontSize="xs" mb={0} color="black">
        Page {currentPage} of {totalPages}
      </Text> */}
      <Select
        value={fileCount}
        onChange={handleFileCountChange}
        size="xs"
        width="70px"
        fontSize="xs"
        borderRadius="full"
      >
        <option value={10}>3</option>
        <option value={25}>6</option>
        <option value={50}>9</option>
      </Select>
      <Button
        onClick={handleNextPage}
        isDisabled={currentPage === totalPages}
        bg="#231F1F"
        color="#FFFFFF"
        _hover={{ bg: "#404040" }}
        borderRadius="full"
        size="xs"
        fontSize="xs"
      >
        Next
      </Button>
    </HStack>
  );
};

export default Pagination;
