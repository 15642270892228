import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Tab,
  TabList,
  Tabs,
  useColorMode,
  HStack,
  IconButton,
  Text,
  Button,
  Spacer,
} from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import UpcomingUpdates from "./UpcomingUpdates";
import UpdateOrganization from "./UpdateOrganization";
import CreateOrganization from "./CreateOrganization";
import Credentials from "./Credentials";

const WebsiteSettings = () => {
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [tabIndex, setTabIndex] = useState(
    parseInt(localStorage.getItem("selectedTabIndex")) || 0
  );

  const handleTabsChange = (index) => {
    setTabIndex(index);
    localStorage.setItem("selectedTabIndex", index.toString()); // Store the selected tab index in localStorage
  };

  const goToHomePage = () => {
    history.push("/program");
  };

  return (
    <Box
      minHeight="100vh"
      maxHeight="100vh"
      display="flex"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      overflow="hidden"
    >
      {/* Left Box with Tabs */}
      <Box
        flex="2"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        display="flex"
        flexDirection="column"
        maxHeight="calc(100vh - 8px - 8px)"
        minHeight="calc(100vh - 8px - 8px)"
        minW="calc(20% + 8px)"
        mr={0}
        mt={2}
        ml={2}
        mb={2}
        borderRadius="lg"
        p={4}
        pb={1}
      >
        <Box>
          <Box display="flex" alignItems="center" justifyContent="left">
            <HStack>
              <IconButton
                icon={<FiArrowLeft />}
                bg="transparent"
                onClick={() => history.goBack()}
                aria-label="Back"
                _hover="none"
              />
              <Text fontSize="lg" fontWeight="semibold" mb="0">
                Settings
              </Text>
            </HStack>
          </Box>
        </Box>
        <Tabs
          onChange={handleTabsChange}
          orientation="vertical"
          index={tabIndex} // Set the selected tab index based on the state
        >
          <TabList>
            <Tab textAlign="left" justifyContent="flex-start">
              Upcoming Updates
            </Tab>
            <Tab textAlign="left" justifyContent="flex-start">
              Update Organization
            </Tab>
            <Tab textAlign="left" justifyContent="flex-start">
              Create Organization
            </Tab>
            <Tab textAlign="left" justifyContent="flex-start">
              Credentials
            </Tab>
            {/* Add more tabs as needed */}
          </TabList>
        </Tabs>
        <Spacer />
        <Button
          mt={1}
          bg="#231F1F"
          color="#FFFFFF"
          _hover={{ bg: "#404040" }}
          borderRadius="full"
          w="40%"
          size="xs"
          onClick={goToHomePage}
        >
          Home Page
        </Button>
      </Box>

      {/* Middle Box for Content */}
      <Box
        flex="8"
        maxW="calc(80% - 32px)"
        minW="calc(80% - 32px)"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        color={colorMode === "dark" ? "white" : "black"}
        display="flex"
        flexDirection="column"
        mr={2}
        mt={2}
        ml={2}
        mb={2}
        borderRadius="lg"
        p={4}
        overflowY="scroll"
        scrollbarWidth="0px"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {tabIndex === 0 && <UpcomingUpdates />}
        {tabIndex === 1 && <UpdateOrganization />}
        {tabIndex === 2 && <CreateOrganization />}
        {tabIndex === 3 && <Credentials />}
        {/* Add conditional rendering for additional tabs */}
      </Box>
    </Box>
  );
};

export default WebsiteSettings;
