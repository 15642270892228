import React, { useState, useEffect } from "react";
import { Box, Select, Button, useToast } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance"; // Import your Axios instance
import usePersistedState from "./../usePersistedState";

const UpdateOrganization = () => {
  const [organizations, setOrganizations] = useState([]);
  //const [selectedOrganization, setSelectedOrganization] = useState(""); // State to store the selected organization
  const [selectedOrganization, setSelectedOrganization] = usePersistedState(
    "selectedOrganizationSettings",
    ""
  );
  const toast = useToast();

  const fetchOrganizations = () => {
    axiosInstance.get("/org/get_organizations").then((response) => {
      if (Array.isArray(response.data.data.organizations)) {
        setOrganizations(response.data.data.organizations);
        // console.log("Organization list", response.data.organizations);
      } else {
        console.error(
          "API did not return an array in 'organizations' key:",
          response.data
        );
        // Handle this case as needed, maybe set organizations to an empty array
        setOrganizations([]);
      }
    });
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  // Function to handle the organization selection and send it to the backend
  const handleOrganizationSelect = () => {
    if (selectedOrganization) {
      // Send selectedOrganization to the backend using a POST request
      // console.log("Selected Organization payload", selectedOrganization);
      axiosInstance
        .post("/website_settings/update-organization", {
          new_organization: selectedOrganization,
        })

        .then((response) => {
          // Handle the response from the backend as needed
          // console.log("Organization updated successfully:", response.data);
          localStorage.removeItem("campaigns");
          // Reload the page after a successful response
          window.location.reload();
          toast({
            title: "Organization Updated",
            description: "Organization name updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          // Handle errors if the POST request fails
          console.error("Error updating organization:", error);
        });
    } else {
      // Handle the case where no organization is selected
      console.error("Please select an organization.");
    }
  };

  return (
    <Box>
      <Select
        placeholder="Select an organization"
        value={selectedOrganization} // Set the selected organization as the value
        onChange={(e) => setSelectedOrganization(e.target.value)}
        w="30%"
      >
        {organizations.map((org, index) => (
          <option key={index} value={org}>
            {org}
          </option>
        ))}
      </Select>
      <Button colorScheme="blue" mt={2} onClick={handleOrganizationSelect}>
        Update Organization
      </Button>
    </Box>
  );
};

export default UpdateOrganization;
