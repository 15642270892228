import React from "react";
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Flex,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { ChevronDownIcon, InfoIcon, LockIcon } from "@chakra-ui/icons";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useAuth, logout } from "../auth";
import { useGoogleAuth } from "./common/google/GoogleAuthContext";
import { useUser } from "./userRole/UserContext";

const UserProfileMenu = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const [logged] = useAuth();
  const { authState } = useGoogleAuth();
  const { user, loading } = useUser();

  const isUserLoggedIn = logged || authState.isLoggedIn;

  const username = localStorage.getItem("username") || "Unknown User";
  const email = localStorage.getItem("email") || "unknown@example.com";
  const role = user ? user.role : null;

  const logoutAndClear = () => {
    logout();
    const keysToRemove = [
      "email",
      "username",
      "currentProgramUploadProgram",
      "showInputFieldUploadProgram",
      "isDropdownOpen",
      "selectedProgram",
      "programslist",
      "selectedOrganization",
      "selectedOrganizationSettings",
      "selectedTabIndex",
      "organization",
      "unsavedManualData",
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    history.push("/login");
  };

  let popoverLinks = [];
  if (loading) {
    // Loading state if needed
  } else if (isUserLoggedIn) {
    popoverLinks = [
      // {
      //   to: "/website_settings",
      //   text: "Website Settings",
      //   icon: <FiSettings />,
      // },

      ...(role === "Admin"
        ? [{ to: "/admin_panel", text: "Users", icon: <LockIcon /> }]
        : []),
      ...(role === "Admin"
        ? [
            {
              to: "/website_settings",
              text: "Website Setting",
              icon: <FiSettings />,
            },
          ]
        : []),
      ...(role === "Manager"
        ? [{ to: "/manager_panel", text: "Users", icon: <LockIcon /> }]
        : []),
      ...(role === "Admin" || role === "Manager" || role === "Employee"
        ? [
            {
              to: "/sender_information",
              text: "Sender Information",
              icon: <InfoIcon />,
            },
          ]
        : []),
      ...(role === "Admin" || role === "Manager" || role === "Employee"
        ? [
            {
              to: "/outreach_profiles",
              text: "Configure Outreach Profiles",
              icon: <LockIcon />,
            },
          ]
        : []),
    ];
  }

  return (
    <Flex align="center">
      <Avatar
        name={username}
        src="https://bit.ly/broken-link"
        cursor="pointer"
        size="sm"
        mr={0}
      />
      <Menu>
        <MenuButton
          as={Button}
          bg="transparent"
          _hover="white"
          _focus="white"
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
        >
          <Text mb={0} bg="transparent" _hover="white" _focus="white">
            {username}
          </Text>
        </MenuButton>
        <MenuList borderRadius="lg" boxShadow="lg" p={2}>
          <Box textAlign="center" p={4} pb={0}>
            <Flex>
              <Avatar
                name={username}
                src="https://bit.ly/broken-link"
                size="lg"
                mb={2}
              />
              <Box ml={4} align="left" pt={2} pb={2}>
                <Text fontWeight="bold" mb={0}>
                  {username}
                </Text>
                <Text fontSize="xs" color="gray.500">
                  {email}
                </Text>
              </Box>
            </Flex>
          </Box>
          <MenuDivider />
          {popoverLinks.map((link, index) => (
            <MenuItem
              as={RouterLink}
              to={link.to}
              key={index}
              icon={link.icon}
              borderRadius="md"
              _hover={{ bg: "gray.100" }}
              _focus={{ bg: "gray.100" }}
            >
              {link.text}
            </MenuItem>
          ))}
          <MenuDivider />
          <MenuItem
            onClick={logoutAndClear}
            icon={<FiLogOut />}
            borderRadius="md"
            _hover={{ bg: "gray.100" }}
            _focus={{ bg: "gray.100" }}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default UserProfileMenu;
