import React, { useEffect } from "react";
import usePersistedState from "./../usePersistedState";
import {
  Box,
  HStack,
  Text,
  Flex,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  IconButton,
  Image,
  Collapse,
} from "@chakra-ui/react";
import { DeleteIcon, ChevronDownIcon } from "@chakra-ui/icons";
import "../../styles/test.css";
import { useColorMode } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import logo from "../../assets/images/alveelogo.png";
import CreateButton from "./CreateButton";
import CurrentUserOrganization from "../CurrentUserOrganization";

const Program_CRUD = ({
  selectedProgram,
  setSelectedProgram,
  programName,
  setProgramName,
  fetchSelectedProgram,
  programs,
  setPrograms,
  showInputField,
  setShowInputField,
  currentProgram,
  setCurrentProgram,
  handleAddProgram,
  fetchAllPrograms,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  // const organization = localStorage.getItem("organization");
  const [isDropdownOpen, setIsDropdownOpen] = usePersistedState(
    "isDropdownOpen",
    false
  );

  
  const handleRemoveProgram = async (programToRemove) => {
    if (programToRemove._id) {
      try {
        const response = await axiosInstance.delete(
          `/program/delete-program/${programToRemove._id}`
        );
        if (response.status === 200) {
          toast({
            title: "Success",
            description: response.data.message,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });

          if (selectedProgram && selectedProgram._id === programToRemove._id) {
            setSelectedProgram(null);
            setProgramName("");
            setCurrentProgram("");
          }

          fetchAllPrograms();
        } 
      } catch (error) {
        if(error.response.status === 501){
          toast({
            title: "Error",
            description: error.response.data.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });

          if (selectedProgram && selectedProgram._id === programToRemove._id) {
            setSelectedProgram(null);
            setProgramName("");
            setCurrentProgram("");
          }

          fetchAllPrograms();
        }
        else {
        console.error(error);
        let errorMessage = "An error occurred while uploading the file.";

        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (error.message) {
          errorMessage = error.message;
        }
    
        console.error("Error uploading file:", error);
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
      }
    }
  };

  useEffect(() => {
    fetchAllPrograms();
  }, []);

  return (
    <Box w="100%" bg="#0F123F" borderRightRadius="xl">
      <Box
        width={{ base: "100%", md: "100%" }}
        height={{ base: "10vh", md: "15vh" }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
        pt={10}
        pl={4}
        pr={4}
      >
        <HStack>
          <Image
            src={logo}
            alt="Logo"
            boxSize="30px"
            mr={2}
            borderRadius="lg"
          />
          <Text fontSize="md" fontWeight="semibold" mb="0" color="#EAEAEF">
            {/* {organization} */}
            <CurrentUserOrganization/>
          </Text>
        </HStack>
      </Box>

      <Box
        mt={6}
        mb={2}
        pt={0}
        pr={2}
        overflowY="scroll"
        h="71vh"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Box
          as="button"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          p={4}
          pb={2}
          color={colorMode === "dark" ? "white" : "white"}
          borderRadius="md"
        >
          <Text fontSize="sm" fontWeight="semibold" mb={0}>
            Program
          </Text>
          <ChevronDownIcon />
        </Box>
        <Collapse in={isDropdownOpen}>
          <Accordion allowMultiple mt={2}>
            {programs
              .filter(
                (programObj) => programObj.name && programObj.name.trim() !== ""
              )
              .map((programObj) => {
                const isSelected =
                  programObj._id ===
                  (selectedProgram ? selectedProgram._id : null);
                return (
                  <AccordionItem key={programObj._id} border="none">
                    <HStack spacing={4} position="relative">
                      {isSelected && (
                        <Box
                          position="absolute"
                          right={0}
                          top={0}
                          bottom={0}
                          width="3px"
                          bg="#FEA130"
                          borderRadius="md"
                        />
                      )}
                      <AccordionButton
                        onClick={() => {
                          // setSelectedProgram(programObj);
                          fetchSelectedProgram(programObj._id);
                        }}
                        _hover={{ bg: "transparent" }}
                        _focus={{ boxShadow: "none" }}
                      >
                        <Text
                          mb={0}
                          ml={2}
                          color={isSelected ? "#FEA130" : "gray.200"}
                          fontWeight={isSelected ? "bold" : "normal"}
                        >
                          {programObj.name}
                        </Text>
                      </AccordionButton>
                      <IconButton
                        variant="ghost"
                        aria-label={`Delete ${programObj.name}`}
                        icon={<DeleteIcon />}
                        color="white"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you want to delete the Program: ${programObj.name}?`
                            )
                          ) {
                            handleRemoveProgram(programObj);
                          }
                        }}
                        size="xs"
                        mr={6}
                      />
                    </HStack>
                  </AccordionItem>
                );
              })}
          </Accordion>
        </Collapse>
      </Box>
      {programs.length !== 0 && (
        <CreateButton
          showInputField={showInputField}
          setShowInputField={setShowInputField}
          currentProgram={currentProgram}
          setCurrentProgram={setCurrentProgram}
          handleAddProgram={handleAddProgram}
          width="80%"
          color="white"
        />
      )}
    </Box>
  );
};

export default Program_CRUD;
