import React, { useState, useEffect } from 'react';
import { Select, useToast } from '@chakra-ui/react';
import axiosInstance from '../../helpers/axiosInstance';

function SenderProfileDropdown({ selectedValue, onChange = () => {}, programId, fileUniqueId }) {
  const [profiles, setProfiles] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchSenderProfile();
  }, []);

  const fetchSenderProfile = async () => {
    try {
      const response = await axiosInstance.get('/sender_profile/');
      if (response.status === 200 && response.data.success) {
        setProfiles(response.data.data.profiles || []);
      }
    } catch (error) {
      console.error('Error fetching profiles:', error);
    }
  };

  const handleChange = async (value) => {
    const selectedProfile = profiles.find(profile => profile._id === value);
    if (!selectedProfile) return;

    const payload = {
      sender_profile_id: selectedProfile._id,
      sender_name: selectedProfile.sender_name,
    };

    try {
      const response = await axiosInstance.post(
        `/program/set-sender-profile/${programId}/${fileUniqueId}`,
        payload
      );
      if (response.status === 200 && response.data.success) {
        toast({
          title: 'Sender Profile Updated',
          description: response.data.message,
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
      }
      onChange(value); // Call onChange to trigger fetchSelectedProgram
    } catch (error) {
      console.error('Error updating sender profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to update the sender profile.',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <Select
      value={selectedValue}
      onChange={(e) => handleChange(e.target.value)}
      placeholder="Select"
      size="sm"
      borderRadius="full"
    >
      {profiles.map((profile) => (
        <option key={profile._id} value={profile._id}>
          {profile.sender_name}
        </option>
      ))}
    </Select>
  );
}

export default SenderProfileDropdown;
