import React, { createContext, useContext, useState } from "react";

const GoogleAuthContext = createContext({
  authState: { isLoggedIn: false, token: null },
  login: () => {},
  logout: () => {}
});

export const GoogleAuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isLoggedIn: false,
    token: null,
  });

  const login = (token) => {
    setAuthState({
      isLoggedIn: true,
      token,
    });
  };

  const logout = () => {
    setAuthState({
      isLoggedIn: false,
      token: null,
    });
  };

  return (
    <GoogleAuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </GoogleAuthContext.Provider>
  );
};

export const useGoogleAuth = () => useContext(GoogleAuthContext);
