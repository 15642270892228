import React, { useState } from "react";
import { Box, Input, Button, useToast, Stack, Heading } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance"; // Import your Axios instance

const CreateOrganization = () => {
  const [newOrganization, setNewOrganization] = useState("");
  const [modulesAllowed, setModulesAllowed] = useState("");
  const toast = useToast();

  const handleNewOrganization = () => {
    const payload = {
      organization: newOrganization,
      settings: {
        modulesAllowed: modulesAllowed,
      },
    };

    axiosInstance
      .post("/org/create-organization", payload)
      .then((response) => {
        // Handle success response
        //console.log("Organization created successfully:", response.data);
        toast({
          title: "Organization Created",
          description: response.data.message, // Correctly access the message from the response
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // You might want to reset the form or redirect the user
      })
      .catch((error) => {
        // Handle errors if the POST request fails
        let errorMessage = "Failed to create new organization."; // Default error message
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the error response contains a custom message, use it
          errorMessage = error.response.data.message;
        }
        //console.error("Error creating new organization:", errorMessage);
        toast({
          title: "Error",
          description: errorMessage, // Use the potentially custom error message
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Box>
      <Stack spacing={4}>
        <Input
          placeholder="New Organization Name"
          value={newOrganization}
          onChange={(e) => setNewOrganization(e.target.value)}
          w="30%"
        />
        <Input
          placeholder="Programs Allowed"
          value={modulesAllowed}
          onChange={(e) => setModulesAllowed(e.target.value)}
          w="30%"
        />
        <Box display="inline-block">
          {" "}
          {/* Wrap the button to control its width */}
          <Button
            colorScheme="blue"
            onClick={handleNewOrganization}
            width="auto"
            minWidth="initial" // Ensures the button's width is just enough for its content
          >
            Create Organization
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreateOrganization;
