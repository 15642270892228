import React, { useState, useEffect } from "react";
import {
  Box,
  Tooltip,
  Icon,
  Button,
  Text,
  Input,
  VStack,
  Flex,
  Heading,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Select,
  useToast,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorMode,
  Spacer,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance";
import { logout } from "../../auth";
import UserProfileMenu from "../UserProfileMenu";
import Pagination from "../Program/MiddlePannel/Pagination";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import CurrentUserOrganization from "../CurrentUserOrganization";

function ManagerPanel() {
  const [users, setUsers] = useState([]);
  const roles = ["Manager", "Employee"];
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({}); // For form validation errors
  const history = useHistory();
  // Validate form data
  const validate = (data) => {
    const errors = {};
    if (!data.username) errors.username = "Username is required";
    if (!data.email) errors.email = "Email is required";
    if (!data.password) errors.password = "Password is required";
    if (!data.role) errors.role = "Role is required";
    return errors;
  };
  const initialState = {
    username: "",
    email: "",
    password: "",
    role: "",
  };

  const [newUserData, setNewUserData] = useState(initialState);
  const [emailToDelete, setEmailToDelete] = useState("");
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [fileCount, setFileCount] = useState(7);
  const [totalPages, setTotalPages] = useState(1);
  const [isGeneratingContent, setIsGeneratingContent] = useState(false);
  const [isRoleChangeAlertOpen, setIsRoleChangeAlertOpen] = useState(false);
  const [isDeleteUserAlertOpen, setDeleteUserAlertOpen] = useState(false);
  const [userToChange, setUserToChange] = useState({ email: "", role: "" });
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  // const organization = localStorage.getItem("organization");

  useEffect(() => {
    fetchUsers(currentPage, fileCount);
  }, [fileCount]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      // Use the function form of setCurrentPage to ensure correct update
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchUsers(nextPage, fileCount); // Pass nextPage directly
        // console.log("current page (after increment):", nextPage);
        return nextPage;
      });
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const prevPageNumber = prevPage - 1;
        fetchUsers(prevPageNumber, fileCount);
        return prevPageNumber;
      });
    }
  };

  const fetchUsers = (currentPage, fileCount) => {
    axiosInstance
      .get(`/auth/admin/get_users?page=${currentPage}&limit=${fileCount}`)
      .then((response) => {
        //console.log("API Response:", response.data);
        if (response.data && response.data.data) {
          setUsers(response.data.data);
          if (response.data && response.data.meta) {
            setTotalPages(response.data.meta.total_pages);
          }
        } else {
          console.error("Unexpected API response format");
          if (response.status === 401) {
            toast({
              title: "Session expired",
              description: "Your session has expired. Please login again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
      });
  };

  useEffect(() => {
    fetchUsers(currentPage, fileCount);
  }, [currentPage, fileCount]);

  const addUser = () => {
    //console.log("newUserData", newUserData);
    setIsGeneratingContent(true);
    axiosInstance
      .post("/manager/add_user", newUserData)
      .then((response) => {
        //console.log("newUserData response", response);
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "User Created Successesfully",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
          setIsOpen(false);
          setIsGeneratingContent(false);
        } else {
          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        setNewUserData(initialState); // Reset the state
        setIsOpen(false); // Close the modal
        fetchUsers(currentPage, fileCount); // Refresh the user list after adding

        setIsGeneratingContent(false);
      })
      .catch((error) => {
        //console.log(error);
        setIsGeneratingContent(false);
        if (error.response && error.response.status === 400) {
          toast({
            title: "User Creation Failed",
            description: "User already exists.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const changeUserRole = (email, role) => {
    //console.log("Changing user role function");
    const payload = {
      email: email,
      role: role,
    };
    //console.log("payload:", payload);

    axiosInstance
      .put("/auth/admin/change_role", payload)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "Role changed successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
        // Refresh the user list
        fetchUsers(currentPage, fileCount);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const deleteUser = () => {
    axiosInstance
      .delete(
        `/auth/admin/delete_user?email=${encodeURIComponent(emailToDelete)}`
      )
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "User Deleted Successesfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          if (response.status === 401) {
            logout();
            toast({
              title: "Session expired",
              description: "Your session has expired. Please login again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
        // Refresh the user list
        fetchUsers(currentPage, fileCount);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // Handle form submission
  const handleSubmit = () => {
    // Check if the password is at least 8 characters.
    if (newUserData.password.length < 8) {
      setPasswordError("Password should be at least 8 characters.");

      // Show the error toast
      toast({
        title: "Error",
        description: "Password should be at least 8 characters.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop further execution
    }
    const validationErrors = validate(newUserData);
    if (Object.keys(validationErrors).length === 0) {
      // No errors, you can now submit data
      //console.log(newUserData);
      addUser();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Box
      width={{ base: "100%", md: "100%" }}
      height="100vh"
      display="flex"
      alignItems="top"
      justifyContent="top"
    >
      <Box
        direction="column"
        height="100vh"
        width={{ base: "100%", md: "100%" }}
      >
        <Box
          width={{ base: "100%", md: "100%" }}
          height="15vh"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          pt={10}
          pl={6}
          pr={5}
        >
          <HStack>
            <IconButton
              icon={<FiArrowLeft />}
              bg="transparent"
              // color="white"
              onClick={() => history.goBack()}
              aria-label="Back"
              _hover="none"
              size="lg"
            />
            <Heading fontWeight="bold" mb="0" color="black">
              {/* User Profiles */}
              <CurrentUserOrganization />
            </Heading>
          </HStack>
          <Spacer />

          <Button
            onClick={() => setShowAddUserModal(true)}
            bg="#231F1F"
            color="#FFFFFF"
            _hover={{ bg: "#404040" }}
            borderRadius="full"
            size="sm"
            mr={3}
          >
            Add User
          </Button>

          <UserProfileMenu />
        </Box>
        <Box p={5} pl={8} pr={8} pb={2} h="80vh">
          <Box
            width={{ base: "100%", md: "100%" }}
            h="100%"
            bg="white"
            borderRadius="xl"
            p={3}
            pb={1}
            borderColor="blackAlpha.200"
            borderWidth="1px"
          >
            <Box h="100%" overflowY="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>USERNAME</Th>
                    <Th>EMAIL</Th>
                    <Th>ROLE</Th>
                    <Th pr={0}>ORGANIZATION</Th>
                    <Th textAlign="center" pl={0}>
                      ACTIONS
                    </Th>
                    <Th>CHANGE ROLE</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.isArray(users) &&
                    users.slice(0, fileCount).map((user, index) => (
                      <Tr key={index}>
                        <Td>{user.username}</Td>
                        <Td>{user.email}</Td>
                        <Td>{user.role}</Td>
                        <Td pr={0}>{user.organization}</Td>
                        {/* Display organization name */}
                        <Td textAlign="center" pl={0}>
                          <IconButton
                            icon={<DeleteIcon />}
                            colorScheme="red"
                            // onClick={() => setDeleteUserAlertOpen(true)}
                            onClick={() => {
                              setEmailToDelete(user.email);
                              setDeleteUserAlertOpen(true);
                            }}
                            aria-label="Delete API Keys"
                            borderRadius="full"
                            size="sm"
                          />
                        </Td>
                        <AlertDialog
                          isOpen={isDeleteUserAlertOpen}
                          onClose={() => setDeleteUserAlertOpen(false)}
                        >
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader
                                fontSize="lg"
                                fontWeight="bold"
                              >
                                Delete User
                              </AlertDialogHeader>

                              <AlertDialogBody>
                                Are you sure you want to delete the user?
                              </AlertDialogBody>

                              <AlertDialogFooter>
                                <Button
                                  variant="outline"
                                  onClick={() => setDeleteUserAlertOpen(false)}
                                >
                                  No
                                </Button>
                                <Button
                                  colorScheme="red"
                                  bgColor="red.500"
                                  textColor="white"
                                  onClick={() => {
                                    deleteUser();
                                    setDeleteUserAlertOpen(false);
                                  }}
                                  ml={3}
                                >
                                  Yes
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>
                        <Td>
                          <Select
                            value={user.role}
                            onChange={(e) => {
                              const updatedRole = e.target.value;
                              setUserToChange({
                                email: user.email,
                                role: updatedRole,
                              });
                              setIsRoleChangeAlertOpen(true);
                            }}
                            borderRadius="full"
                          >
                            <option value="" disabled>
                              Select a role
                            </option>
                            {roles.map((role, idx) => (
                              <option key={idx} value={role}>
                                {role}
                              </option>
                            ))}
                          </Select>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            fileCount={fileCount}
            setFileCount={setFileCount}
          />
        </Box>
        <Modal
          isOpen={showAddUserModal}
          onClose={() => setShowAddUserModal(false)}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent h="78vh">
            <ModalHeader>Add New User</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowY="auto">
              <FormControl isInvalid={errors.username}>
                <FormLabel htmlFor="text" fontWeight="semibold">
                  Username
                </FormLabel>
                <Input
                  id="text"
                  type="text"
                  placeholder="Your username"
                  maxLength={25}
                  value={newUserData.username}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, username: e.target.value })
                  }
                  borderRadius="full"
                />
                <FormErrorMessage>{errors.username}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.email} mt={4}>
                <FormLabel htmlFor="email" fontWeight="semibold">
                  Email
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder="Your email"
                  maxLength={80}
                  value={newUserData.email}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, email: e.target.value })
                  }
                  borderRadius="full"
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!passwordError} mt={4}>
                <FormLabel htmlFor="password" fontWeight="semibold">
                  Password
                </FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Your password"
                  value={newUserData.password}
                  onChange={(e) => {
                    const passwordValue = e.target.value;
                    if (passwordValue.length < 8) {
                      setPasswordError(
                        "Password should be at least 8 characters."
                      );
                      setIsPasswordValid(false);
                    } else {
                      setPasswordError("");
                      setIsPasswordValid(true);
                    }
                    setNewUserData({ ...newUserData, password: passwordValue });
                  }}
                  borderRadius="full"
                />
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.role} mt={4}>
                <FormLabel fontWeight="semibold">Role</FormLabel>
                <Select
                  value={newUserData.role}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, role: e.target.value })
                  }
                  borderRadius="full"
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {roles.map((role, idx) => (
                    <option key={idx} value={role}>
                      {role}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.role}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                bg="#231F1F"
                color="#FFFFFF"
                _hover={{ bg: "#404040" }}
                borderRadius="full"
                size="sm"
                onClick={handleSubmit}
                isDisabled={!isPasswordValid}
                isLoading={isGeneratingContent}
              >
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={isRoleChangeAlertOpen}
          onClose={() => setIsRoleChangeAlertOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Change Role
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to change the role?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsRoleChangeAlertOpen(false)}
                >
                  No
                </Button>
                <Button
                  colorScheme="red"
                  bgColor="red.500"
                  textColor="white"
                  onClick={() => {
                    changeUserRole(userToChange.email, userToChange.role);
                    setIsRoleChangeAlertOpen(false);
                  }}
                  ml={3}
                >
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
}

export default ManagerPanel;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Input,
//   VStack,
//   Heading,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Tfoot,
//   Select,
//   useToast,
//   AlertDialog,
//   AlertDialogBody,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogContent,
//   AlertDialogOverlay,
// } from "@chakra-ui/react";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalCloseButton,
//   ModalBody,
//   ModalFooter,
//   FormControl,
//   FormLabel,
//   FormErrorMessage,
// } from "@chakra-ui/react";
// import { logout } from "../../auth";
// import axiosInstance from "../../helpers/axiosInstance";

// function ManagerPanel() {
//   const [users, setUsers] = useState([]);
//   const roles = ["Manager", "Employee"];
//   const [showAddUserModal, setShowAddUserModal] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [errors, setErrors] = useState({}); // For form validation errors
//   // Validate form data
//   const validate = (data) => {
//     const errors = {};
//     if (!data.username) errors.username = "Username is required";
//     if (!data.email) errors.email = "Email is required";
//     if (!data.password) errors.password = "Password is required";
//     if (!data.role) errors.role = "Role is required";
//     return errors;
//   };
//   const initialState = {
//     username: "",
//     email: "",
//     password: "",
//     role: "",
//   };

//   const [newUserData, setNewUserData] = useState(initialState);
//   const toast = useToast();
//   const [currentPage, setCurrentPage] = useState(1);
//   const [limit, setLimit] = useState(5); // or whatever default limit you want
//   const [totalPages, setTotalPages] = useState(1); // Initialize with 1

//   const navbarHeight = 57;
//   const buttonHeight = 40;
//   const footerHeight = 40;
//   const headingHeight = 40.5;
//   const rowHeight = 73; // Adjust this value according to your desired row height
//   const [isRoleChangeAlertOpen, setIsRoleChangeAlertOpen] = useState(false);
//   const [isDeleteUserAlertOpen, setDeleteUserAlertOpen] = useState(false);
//   const [userToChange, setUserToChange] = useState({ email: "", role: "" });
//   const [passwordError, setPasswordError] = useState("");
//   const [isPasswordValid, setIsPasswordValid] = useState(false);

//   const calculateRowsToFit = () => {
//     const viewportHeight = window.innerHeight;
//     const occupiedSpace =
//       navbarHeight + buttonHeight + footerHeight + headingHeight;
//     const availableSpace = viewportHeight - occupiedSpace;
//     return Math.floor(availableSpace / rowHeight);
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       setLimit(calculateRowsToFit());
//     };

//     // Calculate initially
//     handleResize();

//     // Update on resize
//     window.addEventListener("resize", handleResize);

//     return () => {
//       // Cleanup
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

// useEffect(() => {
//   fetchUsers();
// }, [currentPage, limit]);

//   const addUser = () => {
//     //console.log("newUserData", newUserData);
//     axiosInstance
//       .post("/manager/add_user", newUserData)
//       .then((response) => {
//         //console.log("newUserData response", response);
//         if (response.status === 201) {
//           toast({
//             title: "Status",
//             description: "User Created Successesfully",
//             status: "success",
//             duration: 9000,
//             isClosable: true,
//           });
//         } else {
//           if (response.status === 401) {
//             logout();
//             toast({
//               title: "Session expired",
//               description: "Your session has expired. Please login again.",
//               status: "error",
//               duration: 5000,
//               isClosable: true,
//             });
//           }
//         }

//         setNewUserData(initialState); // Reset the state
//         setIsOpen(false); // Close the modal
//         fetchUsers(); // Refresh the user list after adding
//       })
//       .catch((error) => {
//         //console.log(error);
//         if (error.response && error.response.status === 400) {
//           toast({
//             title: "User Creation Failed",
//             description: "User already exists.",
//             status: "error",
//             duration: 5000,
//             isClosable: true,
//           });
//         }
//       });
//   };

// const fetchUsers = () => {
//   axiosInstance
//     .get(`/auth/admin/get_users?page=${currentPage}&limit=${limit}`)
//     .then((response) => {
//       //console.log("API Response:", response.data);
//       if (response.data && response.data.data) {
//         setUsers(response.data.data);
//         if (response.data && response.data.meta) {
//           setTotalPages(response.data.meta.total_pages);
//         }
//       } else {
//         console.error("Unexpected API response format");
//         if (response.status === 401) {
//           logout();
//           toast({
//             title: "Session expired",
//             description: "Your session has expired. Please login again.",
//             status: "error",
//             duration: 5000,
//             isClosable: true,
//           });
//         }
//       }
//     });
// };

// const changeUserRole = (email, role) => {
//   //console.log("Changing user role function");
//   const payload = {
//     email: email,
//     role: role,
//   };
//   //console.log("payload:", payload);

//   axiosInstance
//     .put("/auth/admin/change_role", payload)
//     .then((response) => {
//       if (response.status === 200) {
//         toast({
//           title: "Status",
//           description: "Role changed successfully",
//           status: "success",
//           duration: 9000,
//           isClosable: true,
//         });
//       }
//       // Refresh the user list
//       fetchUsers();
//     })
//     .catch((error) => {
//       //console.log(error);
//     });
// };

// const deleteUser = (email) => {
//   axiosInstance
//     .delete(`/auth/admin/delete_user?email=${encodeURIComponent(email)}`)
//     .then((response) => {
//       if (response.status === 200) {
//         toast({
//           title: "Status",
//           description: "User Deleted Successesfully",
//           status: "success",
//           duration: 9000,
//           isClosable: true,
//         });
//         if (response.status === 401) {
//           logout();
//           toast({
//             title: "Session expired",
//             description: "Your session has expired. Please login again.",
//             status: "error",
//             duration: 5000,
//             isClosable: true,
//           });
//         }
//       }
//       // Refresh the user list
//       fetchUsers();
//     })
//     .catch((error) => {
//       //console.log(error);
//     });
// };

//   // Handle form submission
// const handleSubmit = () => {
//   // Check if the password is at least 8 characters.
//   if (newUserData.password.length < 8) {
//     setPasswordError("Password should be at least 8 characters.");

//     // Show the error toast
//     toast({
//       title: "Error",
//       description: "Password should be at least 8 characters.",
//       status: "error",
//       duration: 5000,
//       isClosable: true,
//     });
//     return; // Stop further execution
//   }
//   const validationErrors = validate(newUserData);
//   if (Object.keys(validationErrors).length === 0) {
//     // No errors, you can now submit data
//     //console.log(newUserData);
//     addUser();
//   } else {
//     setErrors(validationErrors);
//   }
// };

//   return (
//     <Box overflowX="auto">
//       <Button
//         onClick={() => setShowAddUserModal(true)}
//         ml={4}
//         mb="6px"
//         mt={3}
//         px={5}
//         py={2}
//         boxShadow="md"
//         fontSize="lg"
//         fontWeight="semibold"
//         bgColor="green.500"
//         textColor="white"
//         _hover={{
//           bg: "teal.500",
//           boxShadow: "lg",
//         }}
//       >
//         Add User
//       </Button>
//       <Table variant="simple">
//         <Thead>
//           <Tr>
//             <Th>USERNAME</Th>
//             <Th>EMAIL</Th>
//             <Th>ROLE</Th>
//             <Th>ORGANIZATION</Th> {/* New header */}
//             <Th>ACTIONS</Th>
//             <Th>CHANGE ROLE</Th>
//           </Tr>
//         </Thead>
//         <Tbody>
//           {/* {Array.isArray(users) && users.map((user, index) => ( */}
// {Array.isArray(users) &&
//   users.slice(0, limit).map((user, index) => (
//               <Tr key={index}>
//                 <Td>{user.username}</Td>
//                 <Td>{user.email}</Td>
//                 <Td>{user.role}</Td>
//                 <Td>{user.organization}</Td> {/* Display organization name */}
//                 <Td>
//                   <Button
//                     colorScheme="red"
//                     bgColor="red.500"
//                     textColor="white"
//                     size="sm"
//                     onClick={() => setDeleteUserAlertOpen(true)}
//                   >
//                     Delete
//                   </Button>
//                 </Td>
//                 <AlertDialog
//                   isOpen={isDeleteUserAlertOpen}
//                   onClose={() => setDeleteUserAlertOpen(false)}
//                 >
//                   <AlertDialogOverlay>
//                     <AlertDialogContent>
//                       <AlertDialogHeader fontSize="lg" fontWeight="bold">
//                         Delete User
//                       </AlertDialogHeader>

//                       <AlertDialogBody>
//                         Are you sure you want to delete the user?
//                       </AlertDialogBody>

//                       <AlertDialogFooter>
//                         <Button
//                           variant="outline"
//                           onClick={() => setDeleteUserAlertOpen(false)}
//                         >
//                           No
//                         </Button>
//                         <Button
//                           colorScheme="red"
//                           bgColor="red.500"
//                           textColor="white"
//                           onClick={() => {
//                             deleteUser(user.email);
//                             setDeleteUserAlertOpen(false);
//                           }}
//                           ml={3}
//                         >
//                           Yes
//                         </Button>
//                       </AlertDialogFooter>
//                     </AlertDialogContent>
//                   </AlertDialogOverlay>
//                 </AlertDialog>
//                 <Td>
//                   {/* <Select
//                     defaultValue={user.role}
//                     onChange={(e) => {
//                       const updatedRole = e.target.value;
//                       changeUserRole(user.email, updatedRole);
//                     }}
//                   > */}
// <Select
//   value={user.role}
//   onChange={(e) => {
//     const updatedRole = e.target.value;
//     setUserToChange({
//       email: user.email,
//       role: updatedRole,
//     });
//     setIsRoleChangeAlertOpen(true);
//   }}
// >
//                     <option value="" disabled>
//                       Select a role
//                     </option>
//                     {roles.map((role, idx) => (
//                       <option key={idx} value={role}>
//                         {role}
//                       </option>
//                     ))}
//                   </Select>
//                 </Td>
//               </Tr>
//             ))}
//         </Tbody>
//         <Tfoot ml={0}>
//           <Box mt={2} mb={2} ml={0} display="flex" justifyContent="center">
//             <Button
//               disabled={currentPage === 1}
//               onClick={() => setCurrentPage((prev) => prev - 1)}
//               _hover={{}}
//               bgColor="blue.500"
//               textColor="white"
//             >
//               Previous
//             </Button>
//             <Box mx={2} pt={2}>
//               Page {currentPage} of {totalPages}
//             </Box>
//             <Button
//               disabled={currentPage === totalPages}
//               onClick={() => setCurrentPage((prev) => prev + 1)}
//               _hover={{}}
//               bgColor="blue.500"
//               textColor="white"
//             >
//               Next
//             </Button>
//           </Box>
//         </Tfoot>
//       </Table>
//       <Modal
//         isOpen={showAddUserModal}
//         onClose={() => setShowAddUserModal(false)}
//       >
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Add New User</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <FormControl isInvalid={errors.username}>
//               <FormLabel htmlFor="text">Username</FormLabel>
//               <Input
//                 id="text"
//                 type="text"
//                 placeholder="Your username"
//                 maxLength={25}
//                 value={newUserData.username}
//                 onChange={(e) =>
//                   setNewUserData({ ...newUserData, username: e.target.value })
//                 }
//               />
//               <FormErrorMessage>{errors.username}</FormErrorMessage>
//             </FormControl>

//             <FormControl isInvalid={errors.email} mt={4}>
//               <FormLabel htmlFor="email">Email</FormLabel>
//               <Input
//                 id="email"
//                 type="email"
//                 placeholder="Your email"
//                 maxLength={80}
//                 value={newUserData.email}
//                 onChange={(e) =>
//                   setNewUserData({ ...newUserData, email: e.target.value })
//                 }
//               />
//               <FormErrorMessage>{errors.email}</FormErrorMessage>
//             </FormControl>

//             <FormControl isInvalid={!!passwordError} mt={4}>
//               <FormLabel htmlFor="password">Password</FormLabel>
//               <Input
//                 id="password"
//                 type="password"
//                 placeholder="Your password"
//                 value={newUserData.password}
//                 onChange={(e) => {
//                   const passwordValue = e.target.value;
//                   if (passwordValue.length < 8) {
//                     setPasswordError(
//                       "Password should be at least 8 characters."
//                     );
//                     setIsPasswordValid(false);
//                   } else {
//                     setPasswordError("");
//                     setIsPasswordValid(true);
//                   }
//                   setNewUserData({ ...newUserData, password: passwordValue });
//                 }}
//               />
//               <FormErrorMessage>{passwordError}</FormErrorMessage>
//             </FormControl>

//             <FormControl isInvalid={errors.role} mt={4}>
//               <FormLabel>Role</FormLabel>
// <Select
//   value={newUserData.role}
//   onChange={(e) =>
//     setNewUserData({ ...newUserData, role: e.target.value })
//   }
// >
//                 <option value="" disabled>
//                   Select a role
//                 </option>
//                 {roles.map((role, idx) => (
//                   <option key={idx} value={role}>
//                     {role}
//                   </option>
//                 ))}
//               </Select>
//               <FormErrorMessage>{errors.role}</FormErrorMessage>
//             </FormControl>
//           </ModalBody>
//           <ModalFooter>
//             <Button
//               colorScheme="blue"
//               bgColor="blue.500"
//               textColor="white"
//               onClick={handleSubmit}
//               isDisabled={!isPasswordValid}
//             >
//               Add User
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//       <AlertDialog
//         isOpen={isRoleChangeAlertOpen}
//         onClose={() => setIsRoleChangeAlertOpen(false)}
//       >
//         <AlertDialogOverlay>
//           <AlertDialogContent>
//             <AlertDialogHeader fontSize="lg" fontWeight="bold">
//               Change Role
//             </AlertDialogHeader>

//             <AlertDialogBody>
//               Are you sure you want to change the role?
//             </AlertDialogBody>

//             <AlertDialogFooter>
//               <Button onClick={() => setIsRoleChangeAlertOpen(false)}>
//                 No
//               </Button>
//               <Button
//                 colorScheme="red"
//                 bgColor="red.500"
//                 textColor="white"
//                 onClick={() => {
//                   changeUserRole(userToChange.email, userToChange.role);
//                   setIsRoleChangeAlertOpen(false);
//                 }}
//                 ml={3}
//               >
//                 Yes
//               </Button>
//             </AlertDialogFooter>
//           </AlertDialogContent>
//         </AlertDialogOverlay>
//       </AlertDialog>
//     </Box>
//   );
// }

// export default ManagerPanel;
