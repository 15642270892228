import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { logout } from "../../auth";
import axiosInstance from "../../helpers/axiosInstance";

const OrganizationSettings = ({
  isOpen,
  onOpen,
  onClose,
  selectedOrganization,
  setSelectedOrganization,
  fetchOrganizations,
  fetchUsers,
}) => {
  const toast = useToast();
  const [settings, setSettings] = useState({
    modulesAllowed: 0,
    //... other settings can be added here
  });
  const [newOrganizationName, setNewOrganizationName] =
    useState(selectedOrganization);

  // Fetch the settings when the page loads
  useEffect(() => {
    fetchSettings();
  }, [selectedOrganization]);

  useEffect(() => {
    setNewOrganizationName(selectedOrganization);
  }, [selectedOrganization]);

  const fetchSettings = () => {
    // console.log("Fetching settings");
    // console.log("organization_name", selectedOrganization);
   
    axiosInstance
      .post("/org/fetch-organization-settings", { organization_name: selectedOrganization })
      .then((response) => {
        // console.log("org settings", response.data.data.settings.modulesAllowed);
        if (response.data && response.data.data && response.data.data.settings) {
          setSettings (response.data.data.settings);
        } else {
          // console.log("Settings not found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
  };

  const updateSettings = () => {
    const payload = {
      organization: selectedOrganization,
      settings: settings,
      // new_organization_name: newOrganizationName,
    };
    // console.log("Update Settings payload:", payload);

    axiosInstance
      .put("/org/update-organization-settings", payload)
      .then((response) => {
        // console.log("Settings updated:", response.data);
        fetchOrganizations();
        fetchUsers(selectedOrganization);
        if (response.status === 200) {
          toast({
            title: "Settings Updated Successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          handleError(response);
        }
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };

  const handleError = (response) => {
    if (response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // console.log("Session expired. Please login again.");
    } else {
      console.log(`Error: ${response.status}`);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Organization Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt={4}>
            <FormLabel>Modules Allowed:</FormLabel>
            <Input
              type="number"
              value={settings.modulesAllowed}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  modulesAllowed: parseInt(e.target.value, 10),
                })
              }
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Current Organization Name</FormLabel>
            <Input
              type="text"
              value={newOrganizationName}
              onChange={(e) => setNewOrganizationName(e.target.value)}
              isReadOnly
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            bgColor="blue.500"
            textColor="white"
            mr={3}
            onClick={updateSettings}
          >
            Update Settings
          </Button>
          <Button
            colorScheme="blue"
            bgColor="blue.500"
            textColor="white"
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

OrganizationSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OrganizationSettings;
