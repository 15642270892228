import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
  useColorModeValue,
  Image,
  HStack,
  Link,
  Divider,
} from "@chakra-ui/react";
import axiosInstance from "../helpers/axiosInstance";
import logo from "../assets/images/alveelogo.png";
// import "./../styles/styles.css";

const content = [
  {
    heading: "Forgot Your Password?",
    text: "Enter your email address. We will then send you instructions to reset your password.",
  },
];

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/auth/forgot_password", { email });
      toast({
        title: "Reset Password",
        description: "Please check your email for a password reset link.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "An error occurred.",
        description:
          "An error occurred while trying to send the reset link. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const bgColor = useColorModeValue("white", "#171923");

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.backgroundColor = ""; // Reset to default
      document.body.style.overflow = "unset";
    };
  }, [bgColor]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setAnimationTrigger((prev) => !prev); // Toggle the trigger
        return (prevIndex + 1) % content.length;
      });
    }, 90000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Flex h="100vh">
      {/* Left Side with Lavender Background */}
      <Box w="60%" bg="#FAC900" pt={5} pl={5} pb={40}>
        <Image src={logo} alt="Logo" width="70px" height="auto" />
        <Container maxW="100%" width="full" pt={40}>
          <Heading
            color="#231F1F"
            textAlign="left"
            fontFamily="'ui', sans-serif"
          >
            {content[currentIndex].heading}
          </Heading>
          <Text
            color="#231F1F"
            className={animationTrigger ? "typewriter" : "typewriter alternate"}
            key={currentIndex}
            textAlign="left"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
          >
            {content[currentIndex].text}
          </Text>
        </Container>
      </Box>

      {/* <Box w="60%" bg="lavender" p="40">
        <Container centerContent>
          <Heading color="hsl(270, 60%, 70%)" p={5}>
            Forgot Your Password?
          </Heading>
          <Text color="hsl(270, 60%, 70%)">
            Enter your email to receive a link to reset your password.
          </Text>
        </Container>
      </Box> */}

      {/* Right Side with Forgot Password Form */}
      <Box w="40%" bg="white" p={10} pt="40" pb="40">
        <Container>
          <Stack spacing={4}>
            <Heading
              as="h1"
              size="xl"
              textAlign="left"
              mb={6}
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#3A4135"
            >
              Forgot Password
            </Heading>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel
                  htmlFor="email"
                  fontFamily="'Manrope', sans-serif"
                  fontWeight="medium"
                  color="#3A4135"
                >
                  Email*
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormControl>
              <Button
                mt={4}
                type="submit"
                w="100%"
                bg="#231F1F"
                color="#FFFFFF"
                fontFamily="'Roboto', sans-serif"
                fontWeight="medium"
                _hover={{ bg: "#404040" }}
              >
                Send Reset Link
              </Button>
            </form>
          </Stack>
        </Container>
        <Box alignContent="center" justifyContent="center" p={5} pb={0} pt={40}>
          <HStack alignContent="center" justifyContent="center">
            <Link
              fontSize="12"
              href="/policies/terms-of-use"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
              // mr={4}
            >
              Terms of Use
            </Link>
            <Divider
              alignSelf="center"
              orientation="vertical"
              height="12px" // Adjust height according to your needs
              borderColor="black" // Ensure this is visible against your background
              borderWidth="0.5px"
            />
            <Link
              fontSize="12"
              href="/policies/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default ForgotPassword;


// import React, { useState,useEffect } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   FormControl,
//   FormLabel,
//   Heading,
//   Input,
//   Stack,
//   useColorModeValue,
//   useColorMode
// } from "@chakra-ui/react";
// import { useToast } from "@chakra-ui/react";
// import { Logo } from "./Logo";
// import axiosInstance from "../helpers/axiosInstance";

// const ForgotPassword = () => {
//   const [email, setEmail] = useState("");
//   const toast = useToast();
//   const { colorMode } = useColorMode();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       await axiosInstance.post("/auth/forgot_password", { email });

//       // Show a success toast
//       toast({
//         title: "Reset Password",
//         description: "Please check your email for a password reset link.",
//         status: "success",
//         duration: 9000,
//         isClosable: true,
//       });
//     } catch (error) {
//       console.error(error);

//       // Show an error toast
//       toast({
//         title: "An error occurred.",
//         description:
//           "An error occurred while trying to send the reset link. Please try again.",
//         status: "error",
//         duration: 9000,
//         isClosable: true,
//       });
//     }
//   };

//    // Function to determine the boxShadow based on colorMode and screen size
//    const boxShadow = () => ({
//     base: "none",
//     sm:
//       colorMode === "light"
//         ? "0px 4px 6px -1px rgba(0, 0, 0, 0.15)"
//         : "0px 4px 6px -1px rgba(255, 255, 255, 0.2)",
//   });

//   const bgColor = useColorModeValue("white", "#171923");

//   useEffect(() => {
//     document.body.style.backgroundColor = bgColor;
//     document.body.style.overflow = "hidden";

//     return () => {
//       document.body.style.backgroundColor = ""; // Reset to default
//       document.body.style.overflow = "unset";
//     };
//   }, [bgColor]);

//   return (
//     <Container
//       maxW="lg"
//       py={{
//         base: "10",
//         md: "20",
//       }}
//       px={{
//         base: "0",
//         sm: "8",
//       }}
//     >
//       <Stack spacing="8">
//         <Stack spacing="6">
//           <Logo />
//           <Heading
//             size={{
//               base: "xs",
//               md: "30px",
//             }}
//             textAlign="center"
//           >
//             Forgot Password
//           </Heading>
//         </Stack>
//         <Box
//           py={{
//             base: "0",
//             sm: "8",
//           }}
//           px={{
//             base: "4",
//             sm: "10",
//           }}
//           bg={{
//             base: "transparent",
//             sm: "bg.surface",
//           }}
//           // boxShadow={{
//           //   base: "none",
//           //   sm: "md",
//           // }}
//           boxShadow={boxShadow()}
//           borderRadius={{
//             base: "none",
//             sm: "xl",
//           }}
//         >
//           <Box as="form" onSubmit={handleSubmit}>
//             <Stack spacing="6">
//               <FormControl>
//                 <FormLabel htmlFor="email">Email</FormLabel>
//                 <Input
//                   id="email"
//                   name="email"
//                   type="email"
//                   placeholder="Enter email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   required
//                 />
//               </FormControl>
//               <Button type="submit"> Reset Password</Button>
//             </Stack>
//           </Box>
//         </Box>
//       </Stack>
//     </Container>
//   );
// };

// export default ForgotPassword;
