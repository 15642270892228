import React, { useState, useEffect } from "react";
import {
  Box,
  Tooltip,
  Icon,
  Button,
  Text,
  Input,
  VStack,
  Flex,
  Heading,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Select,
  useToast,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorMode,
  Spacer,
} from "@chakra-ui/react";
import { SettingsIcon, DeleteIcon } from "@chakra-ui/icons";
import OrganizationSettings from "./OrganizationSettings";
import axiosInstance from "../../helpers/axiosInstance";
import { useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import UserProfileMenu from "../UserProfileMenu";
import Pagination from "../Program/MiddlePannel/Pagination";

function AdminPanel() {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [users, setUsers] = useState([]);
  const roles = ["Admin", "Manager", "Employee"];
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { colorMode } = useColorMode();
  const history = useHistory();
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const [errors, setErrors] = useState({}); // For form validation errors
  // Validate form data
  const validate = (data) => {
    const errors = {};
    if (!data.username) errors.username = "Username is required";
    if (!data.email) errors.email = "Email is required";
    if (!data.password) errors.password = "Password is required";
    if (!data.role) errors.role = "Role is required";
    return errors;
  };
  const initialState = {
    username: "",
    email: "",
    password: "",
    role: "",
  };

  const [newUserData, setNewUserData] = useState(initialState);
  const [emailToDelete, setEmailToDelete] = useState("");
  const toast = useToast();
  const [isGeneratingContent, setIsGeneratingContent] = useState(false);
  const [isRoleChangeAlertOpen, setIsRoleChangeAlertOpen] = useState(false);
  const [isDeleteUserAlertOpen, setDeleteUserAlertOpen] = useState(false);
  const [userToChange, setUserToChange] = useState({ email: "", role: "" });
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileCount, setFileCount] = useState(7);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (selectedOrganization) {
      fetchUsers(selectedOrganization, currentPage, fileCount);
    }
  }, [fileCount]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      // Use the function form of setCurrentPage to ensure correct update
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchUsers(selectedOrganization, nextPage, fileCount); // Pass nextPage directly
        // console.log("current page (after increment):", nextPage);
        return nextPage;
      });
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const prevPageNumber = prevPage - 1;
        fetchUsers(selectedOrganization, prevPageNumber, fileCount);
        return prevPageNumber;
      });
    }
  };

  const fetchOrganizations = () => {
    axiosInstance.get("/org/get_organizations").then((response) => {
      // console.log("API Response:", response.data.data);
      if (Array.isArray(response.data.data.organizations)) {
        setOrganizations(response.data.data.organizations);
      } else {
        console.error(
          "API did not return an array in 'organizations' key:",
          response.data
        );
        // Handle this case as needed, maybe set organizations to an empty array
        setOrganizations([]);
      }
    });
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (selectedOrganization) {
      fetchUsers(selectedOrganization, currentPage, fileCount);
    }
  }, [selectedOrganization, currentPage, fileCount]);

  const fetchUsers = (orgName, currentPage, fileCount) => {
    if (!orgName) return; // Exit if no organization name is provided

    axiosInstance
      .get(
        `/auth/admin/get_users?page=${currentPage}&limit=${fileCount}&organization=${orgName}`
      )
      .then((response) => {
        // console.log("API Response:", response.data);
        if (response.data && response.data.data) {
          setUsers(response.data.data);
          if (response.data && response.data.meta) {
            setTotalPages(response.data.meta.total_pages);
            // console.log("Total pages:", response.data.meta.total_pages);
          }
        } else {
          console.error("Unexpected API response format");

          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const addUser = () => {
    //console.log("newUserData", newUserData);
    setIsGeneratingContent(true);
    axiosInstance
      .post("/auth/signup", {
        ...newUserData,
        organization: selectedOrganization,
      })
      .then((response) => {
        //console.log("newUserData response", response);
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "User Created Successesfully",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
          setIsOpen(false);
          setIsGeneratingContent(false);
        } else {
          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        setNewUserData(initialState); // Reset the state
        setIsOpen(false); // Close the modal
        fetchUsers(selectedOrganization, currentPage, fileCount); // Refresh the user list after adding
        fetchOrganizations();
        setIsGeneratingContent(false);
      })
      .catch((error) => {
        //console.log(error);
        setIsGeneratingContent(false);
        if (error.response && error.response.status === 400) {
          toast({
            title: "User Creation Failed",
            description: "User already exists.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const changeUserRole = (email, role) => {
    //console.log("Changing user role function");
    const payload = {
      email: email,
      role: role,
    };
    //console.log("payload:", payload);
    axiosInstance
      .put("/auth/admin/change_role", payload)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "Role changed successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        // Refresh the user list
        fetchUsers(selectedOrganization, currentPage, fileCount);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const deleteUser = () => {
    axiosInstance
      .delete(
        `/auth/admin/delete_user?email=${encodeURIComponent(emailToDelete)}`
      )
      .then((response) => {
        // Refresh the user list
        fetchUsers(selectedOrganization, currentPage, fileCount);
        fetchOrganizations();
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "User Deleted Successesfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // Handle form submission
  const handleSubmit = () => {
    // Check if the password is at least 8 characters.
    if (newUserData.password.length < 8) {
      setPasswordError("Password should be at least 8 characters.");

      // Show the error toast
      toast({
        title: "Error",
        description: "Password should be at least 8 characters.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop further execution
    }
    const validationErrors = validate(newUserData);
    if (Object.keys(validationErrors).length === 0) {
      // No errors, you can now submit data
      //console.log(newUserData);
      addUser();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSetOrganization = (org) => {
    setSelectedOrganization(org);
    localStorage.setItem("selectedOrganization", org); // Save to local storage
  };

  useEffect(() => {
    const savedOrg = localStorage.getItem("selectedOrganization");
    if (savedOrg) {
      setSelectedOrganization(savedOrg); // Set the saved organization
    }
  }, []);

  const goToHomePage = () => {
    history.push("/program");
  };
  return (
    <Box
      width={{ base: "100%", md: "100%" }}
      height="100vh"
      display="flex"
      alignItems="top"
      justifyContent="top"
    >
      <Box w="17%" bg="#0F123F" borderRightRadius="xl">
        <Box
          width={{ base: "100%", md: "100%" }}
          height={{ base: "10vh", md: "15vh" }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          pt={4}
          pl={2}
          pr={4}
        >
          <HStack>
            <IconButton
              icon={<FiArrowLeft />}
              bg="transparent"
              color="white"
              onClick={() => history.goBack()}
              aria-label="Back"
              _hover="none"
            />
            <Text fontSize="md" fontWeight="semibold" mb="0" color="#EAEAEF">
              Organizations
            </Text>
          </HStack>
        </Box>
        <Box
          mt={2}
          mb={2}
          p={4}
          pt={0}
          // overflowY="scroll"
          h="80vh"
          // sx={{
          //   "&::-webkit-scrollbar": {
          //     display: "none",
          //   },
          //   msOverflowStyle: "none",
          //   scrollbarWidth: "none",
          // }}
          justifyContent="space-between"
        >
          <VStack
            spacing={2}
            w="100%"
            h="76vh"
            overflowY="scroll"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Accordion allowToggle width="100%">
              {organizations.map((org) => (
                <AccordionItem key={org}>
                  <AccordionButton
                    onClick={() => {
                      handleSetOrganization(org);
                      setCurrentPage(1); // reset page to 1 whenever you change organizations
                      // console.log("Organization selected", org);
                    }}
                    _hover={{ bg: "transparent" }}
                    _focus={{ boxShadow: "none" }}
                  >
                    <Box
                      flex="1"
                      textAlign="left"
                      color={
                        org === selectedOrganization
                          ? "#FEA130"
                          : colorMode === "dark"
                          ? "gray.200"
                          : "gray.200"
                      }
                      fontWeight={selectedOrganization ? "bold" : "normal"}
                    >
                      {org}
                    </Box>
                  </AccordionButton>
                </AccordionItem>
              ))}
            </Accordion>
          </VStack>
          <Button
            bg="#FEA130"
            color="#FFFFFF"
            _hover="none"
            borderRadius="full"
            size="sm"
            onClick={goToHomePage}
          >
            Home Page
          </Button>
        </Box>
      </Box>

      <Box
        direction="column"
        height="100vh"
        width={{ base: "100%", md: "83%" }}
      >
        <Box
          width={{ base: "100%", md: "100%" }}
          height="15vh"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          pt={10}
          pl={8}
          pr={5}
        >
          <Heading fontWeight="bold" mb="0" color="black">
            {/* User Profiles */}
            {selectedOrganization}
          </Heading>
          <Spacer />

          <Button
            onClick={() => setShowAddUserModal(true)}
            bg="#231F1F"
            color="#FFFFFF"
            _hover={{ bg: "#404040" }}
            borderRadius="full"
            size="sm"
            mr={3}
          >
            Add User
          </Button>

          <UserProfileMenu />
        </Box>
        <Box p={5} pl={8} pr={8} pb={2} h="80vh">
          <Box
            width={{ base: "100%", md: "100%" }}
            h="100%"
            bg="white"
            borderRadius="xl"
            p={3}
            pb={1}
            borderColor="blackAlpha.200"
            borderWidth="1px"
          >
            <Box h="100%" overflowY="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>USERNAME</Th>
                    <Th>EMAIL</Th>
                    <Th>ROLE</Th>
                    <Th pr={0}>
                      <Flex align="center">
                        ORGANIZATION
                        <Button
                          ml={2}
                          onClick={handleOpen}
                          size="sm"
                          variant="unstyled" // Makes the button have no background or border
                        >
                          <Tooltip label="Settings">
                            <Icon as={SettingsIcon} />
                          </Tooltip>
                        </Button>
                      </Flex>
                    </Th>

                    <Th textAlign="center" pl={0}>
                      ACTIONS
                    </Th>
                    <Th>CHANGE ROLE</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.isArray(users) &&
                    users.slice(0, fileCount).map((user, index) => (
                      <Tr key={index}>
                        <Td>{user.username}</Td>
                        <Td>{user.email}</Td>
                        <Td>{user.role}</Td>
                        <Td pr={0}>{user.organization}</Td>
                        {/* Display organization name */}
                        <Td textAlign="center" pl={0}>
                          <IconButton
                            icon={<DeleteIcon />}
                            colorScheme="red"
                            // onClick={() => setDeleteUserAlertOpen(true)}
                            onClick={() => {
                              setEmailToDelete(user.email);
                              setDeleteUserAlertOpen(true);
                            }}
                            aria-label="Delete API Keys"
                            borderRadius="full"
                            size="sm"
                          />
                        </Td>
                        <AlertDialog
                          isOpen={isDeleteUserAlertOpen}
                          onClose={() => setDeleteUserAlertOpen(false)}
                        >
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader
                                fontSize="lg"
                                fontWeight="bold"
                              >
                                Delete User
                              </AlertDialogHeader>

                              <AlertDialogBody>
                                Are you sure you want to delete the user?
                              </AlertDialogBody>

                              <AlertDialogFooter>
                                <Button
                                  variant="outline"
                                  onClick={() => setDeleteUserAlertOpen(false)}
                                >
                                  No
                                </Button>
                                <Button
                                  colorScheme="red"
                                  bgColor="red.500"
                                  textColor="white"
                                  onClick={() => {
                                    deleteUser();
                                    setDeleteUserAlertOpen(false);
                                  }}
                                  ml={3}
                                >
                                  Yes
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>
                        <Td>
                          <Select
                            value={user.role}
                            onChange={(e) => {
                              const updatedRole = e.target.value;
                              setUserToChange({
                                email: user.email,
                                role: updatedRole,
                              });
                              setIsRoleChangeAlertOpen(true);
                            }}
                            borderRadius="full"
                          >
                            <option value="" disabled>
                              Select a role
                            </option>
                            {roles.map((role, idx) => (
                              <option key={idx} value={role}>
                                {role}
                              </option>
                            ))}
                          </Select>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            fileCount={fileCount}
            setFileCount={setFileCount}
          />
        </Box>
        <Modal
          isOpen={showAddUserModal}
          onClose={() => setShowAddUserModal(false)}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent h="78vh">
            <ModalHeader>Add New User</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowY="auto">
              <FormControl isInvalid={errors.username}>
                <FormLabel htmlFor="text" fontWeight="semibold">
                  Username
                </FormLabel>
                <Input
                  id="text"
                  type="text"
                  placeholder="Your username"
                  maxLength={25}
                  value={newUserData.username}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, username: e.target.value })
                  }
                  borderRadius="full"
                />
                <FormErrorMessage>{errors.username}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.email} mt={4}>
                <FormLabel htmlFor="email" fontWeight="semibold">
                  Email
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder="Your email"
                  maxLength={80}
                  value={newUserData.email}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, email: e.target.value })
                  }
                  borderRadius="full"
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!passwordError} mt={4}>
                <FormLabel htmlFor="password" fontWeight="semibold">
                  Password
                </FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Your password"
                  value={newUserData.password}
                  onChange={(e) => {
                    const passwordValue = e.target.value;
                    if (passwordValue.length < 8) {
                      setPasswordError(
                        "Password should be at least 8 characters."
                      );
                      setIsPasswordValid(false);
                    } else {
                      setPasswordError("");
                      setIsPasswordValid(true);
                    }
                    setNewUserData({ ...newUserData, password: passwordValue });
                  }}
                  borderRadius="full"
                />
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.role} mt={4}>
                <FormLabel fontWeight="semibold">Role</FormLabel>
                <Select
                  value={newUserData.role}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, role: e.target.value })
                  }
                  borderRadius="full"
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {roles.map((role, idx) => (
                    <option key={idx} value={role}>
                      {role}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.role}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                bg="#231F1F"
                color="#FFFFFF"
                _hover={{ bg: "#404040" }}
                borderRadius="full"
                size="sm"
                onClick={handleSubmit}
                isDisabled={!isPasswordValid}
                isLoading={isGeneratingContent}
              >
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={isRoleChangeAlertOpen}
          onClose={() => setIsRoleChangeAlertOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Change Role
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to change the role?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsRoleChangeAlertOpen(false)}
                >
                  No
                </Button>
                <Button
                  colorScheme="red"
                  bgColor="red.500"
                  textColor="white"
                  onClick={() => {
                    changeUserRole(userToChange.email, userToChange.role);
                    setIsRoleChangeAlertOpen(false);
                  }}
                  ml={3}
                >
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>

      <OrganizationSettings
        isOpen={isOpen}
        onClose={handleClose}
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        fetchOrganizations={fetchOrganizations}
        fetchUsers={fetchUsers}
      />
    </Box>
  );
}

export default AdminPanel;
