import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
  Image,
  HStack,
  Link,
  Divider,
} from "@chakra-ui/react";
import logo from "../assets/images/alveelogo.png";
import "./../styles/styles.css";

const content = [
  {
    heading: "Reset Your Password",
    text: "enter your new password.",
  },
];

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();
  const toast = useToast();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast({
        title: "Password Mismatch",
        description: "The passwords do not match. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return; // Stop the form submission if passwords do not match
    }

    try {
      await axios.post(`/auth/reset_password/${token}`, { password });
      toast({
        title: "Password Reset Successful",
        description: "Your password has been successfully reset.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/login");
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : "An error occurred while trying to reset your password. Please try again.";
      toast({
        title: "An error occurred.",
        description: errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setAnimationTrigger((prev) => !prev); // Toggle the trigger
        return (prevIndex + 1) % content.length;
      });
    }, 9000); // Change content every 4 seconds
    return () => clearInterval(timer);
  }, []);

  return (
    <Flex h="100vh">
      {/* Left Side with Lavender Background */}
      <Box w="60%" bg="#FAC900" pt={5} pl={5} pb={40}>
        <Image src={logo} alt="Logo" width="70px" height="auto" />
        <Container w="90%" ml={0}  pt={40}>
          <Heading
            color="#231F1F"
            textAlign="left"
            fontFamily="'ui', sans-serif"
          >
            {content[currentIndex].heading}
          </Heading>
          <Text
            color="#231F1F"
            className={animationTrigger ? "typewriter" : "typewriter alternate"}
            key={currentIndex}
            textAlign="left"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
          >
            {content[currentIndex].text}
          </Text>
        </Container>
      </Box>

      {/* <Box w="60%" bg="lavender" p="40">
        <Container centerContent>
          <Heading color="hsl(270, 60%, 70%)" p={5}>
            Reset Your Password
          </Heading>
          <Text color="hsl(270, 60%, 70%)">
            Enter your new password below.
          </Text>
        </Container>
      </Box> */}

      {/* Right Side with Reset Password Form */}
      <Box w="40%" bg="white" p={10} pt="40" pb="40">
        <Container>
          <Stack spacing={4}>
            <Heading
              as="h1"
              size="xl"
              textAlign="left"
              mb={6}
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#3A4135"
            >
              Reset Password
            </Heading>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel
                  htmlFor="password"
                  fontFamily="'Manrope', sans-serif"
                  fontWeight="medium"
                  color="#3A4135"
                >
                  New Password*
                </FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mt="4">
                <FormLabel
                  htmlFor="confirmPassword"
                  fontFamily="'Manrope', sans-serif"
                  fontWeight="medium"
                  color="#3A4135"
                >
                  Confirm New Password*
                </FormLabel>
                <Input
                  id="confirmPassword"
                  type="password"
                  placeholder="Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </FormControl>
              <Button
                mt={4}
                type="submit"
                w="100%"
                bg="#231F1F"
                color="#FFFFFF"
                fontFamily="'Roboto', sans-serif"
                fontWeight="medium"
                _hover={{ bg: "#404040" }}
              >
                Update Password
              </Button>
            </form>
          </Stack>
        </Container>
        <Box alignContent="center" justifyContent="center" p={5} pb={0} pt={20}>
          <HStack alignContent="center" justifyContent="center">
            <Link
              fontSize="12"
              href="/policies/terms-of-use"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
              // mr={4}
            >
              Terms of Use
            </Link>
            <Divider
              alignSelf="center"
              orientation="vertical"
              height="12px" // Adjust height according to your needs
              borderColor="black" // Ensure this is visible against your background
              borderWidth="0.5px"
            />
            <Link
              fontSize="12"
              href="/policies/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
}

export default ResetPassword;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Container,
//   FormControl,
//   FormLabel,
//   Heading,
//   Input,
//   Stack,
//   useColorModeValue,
//   useColorMode,
// } from "@chakra-ui/react";
// import { useToast } from "@chakra-ui/react";
// import { Logo } from "./Logo";
// import { useHistory } from "react-router-dom";

// function ResetPassword() {
//   const [password, setPassword] = useState("");
//   const { token } = useParams();
//   const toast = useToast();
//   const { colorMode } = useColorMode();
//   const history = useHistory();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       await axios.post(`/auth/reset_password/${token}`, { password });

//       // Show a success toast
//       toast({
//         title: "Password Reset Successful",
//         description: "Your password has been successfully reset.",
//         status: "success",
//         duration: 9000,
//         isClosable: true,
//       });

//       // Redirect the user to the login page
//       history.push("/login");
//     } catch (error) {
//       console.error(error);

//       // Extract the error message from the server response
//       const errorMessage =
//         error.response && error.response.data.error
//           ? error.response.data.error
//           : "An error occurred while trying to reset your password. Please try again.";

//       // Show an error toast
//       toast({
//         title: "An error occurred.",
//         description: errorMessage,
//         status: "error",
//         duration: 9000,
//         isClosable: true,
//       });
//     }
//   };

//   // Function to determine the boxShadow based on colorMode and screen size
//   const boxShadow = () => ({
//     base: "none",
//     sm:
//       colorMode === "light"
//         ? "0px 4px 6px -1px rgba(0, 0, 0, 0.15)"
//         : "0px 4px 6px -1px rgba(255, 255, 255, 0.2)",
//   });

//   const bgColor = useColorModeValue("white", "#171923");

//   useEffect(() => {
//     document.body.style.backgroundColor = bgColor;
//     document.body.style.overflow = "hidden";

//     return () => {
//       document.body.style.backgroundColor = ""; // Reset to default
//       document.body.style.overflow = "unset";
//     };
//   }, [bgColor]);

//   return (
//     <Container
//       maxW="lg"
//       py={{
//         base: "10",
//         md: "20",
//       }}
//       px={{
//         base: "0",
//         sm: "8",
//       }}
//     >
//       <Stack spacing="8">
//         <Stack spacing="6">
//           <Logo />
//           <Heading
//             size={{
//               base: "xs",
//               md: "30px",
//             }}
//             textAlign="center"
//           >
//             Reset Password
//           </Heading>
//         </Stack>
//         <Box
//           py={{
//             base: "0",
//             sm: "8",
//           }}
//           px={{
//             base: "4",
//             sm: "10",
//           }}
//           bg={{
//             base: "transparent",
//             sm: "bg.surface",
//           }}
//           // boxShadow={{
//           //   base: "none",
//           //   sm: "md",
//           // }}
//           boxShadow={boxShadow()}
//           borderRadius={{
//             base: "none",
//             sm: "xl",
//           }}
//         >
//           <Box as="form" onSubmit={handleSubmit}>
//             <Stack spacing="6">
//               <FormControl>
//                 <FormLabel htmlFor="password">New Password</FormLabel>
//                 <Input
//                   id="password"
//                   name="password"
//                   type="password"
//                   placeholder="Password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   required
//                 />
//               </FormControl>
//               <Button type="submit">Update Password</Button>
//             </Stack>
//           </Box>
//         </Box>
//       </Stack>
//     </Container>
//   );
// }

// export default ResetPassword;
