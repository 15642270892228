import axiosInstance from ".././../../helpers/axiosInstance";

const Upload = async (data, selectedProgram, fileUniqueId, toast, isManualEntry = false) => {
  // console.log("data", data); // Log the original data object

  let requestData = new FormData();

  if (isManualEntry) {
    // console.log("isManualEntry");
    // Convert the manual data to a JSON string
    const jsonData = JSON.stringify(data);
    // console.log("Manual Data JSON String:", jsonData); // Log the JSON string of the manual data

    // Append the JSON string to FormData with the key 'file', similar to Postman
    requestData.append("file", jsonData);
  } else {
    // console.log("in else");
    // For file upload, append the file to FormData
    requestData.append("file", data);
  }

  try {
    const response = await axiosInstance.post(
      `/program/upload-file/${selectedProgram._id}/${fileUniqueId}`,
      requestData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (response.status === 200) {
      toast({
        title: "Success",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return response.data.data.additional_columns;
    } else {
      throw new Error(response.data.message || "Failed to upload file");
    }
  } catch (error) {
    let errorMessage = "An error occurred while uploading the file.";

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Error uploading file:", error);
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    return null; 
  }
};

export default Upload;
