import React, { useState, useEffect,useRef  } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import { Flex, useToast, Box } from "@chakra-ui/react";
import axiosInstance from "../../../../helpers/axiosInstance";
import GenerationActions from "./GenerationActions";
import GenerationTable from "./GenerationTable";
import ViewDetailsPagination from "./ViewDetailsPagination";
import DeleteDialog from "../DeleteDialog";


const GenerationView = () => {
  const { programId, fileUniqueId } = useParams(); // Destructure params from URL
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileCount, setFileCount] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [currentStatus, setCurrentStatus] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [loadingReExecuteId, setLoadingReExecuteId] = useState(null);
  const [loadingRetryId, setLoadingRetryId] = useState(null);
  const [loadingStopId, setLoadingStopId] = useState(null);
  const [stoppedExecutionId, setStoppedExecutionId] = useState(null);
  // New states for delete dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [executionIdToDelete, setExecutionIdToDelete] = useState(null);
  const cancelRef = useRef();
  const toast = useToast();

  useEffect(() => {
    // Fetch the execution data when the component mounts
    fetchSelectedProgram(programId, currentPage, fileCount);
    // console.log("setSelectedProgram", selectedProgram);
  }, [programId, currentPage, fileCount]);

  const fetchSelectedProgram = async (
    programId,
    page = currentPage,
    count = fileCount
  ) => {
    if (!programId) return;
    try {
      const response = await axiosInstance.post(
        `/program/fetch-program/${programId}`,
        {
          file_count: count,
          page_no: page,
        }
      );
      if (response.status === 200) {
        const program = response.data.data;
        setSelectedProgram(program);
        const selectedFileRow = program.file_list.find(
          (file) => file.file_unique_id === fileUniqueId
        );
        setSelectedRow(selectedFileRow);
        if (program.page_count) {
          setTotalPages(program.page_count);
        }
      }
    } catch (error) {
      console.error("Error fetching selected program:", error);
      let errorMessage = "An error occurred while fetching the program.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const fetchStatus = async (executionId) => {
    try {
      const response = await axiosInstance.get(
        `/content-generation/get-status-by-execution-id/${selectedProgram._id}/${selectedRow.file_unique_id}/${executionId}`
      );

      if (response.status === 200 && response.data?.data?.status) {
        setCurrentStatus((prevStatus) => ({
          ...prevStatus,
          [executionId]: response.data.data.status,
        }));
        return response.data.data.status;
      } else {
        throw new Error("Status not found in response");
      }
    } catch (error) {
      console.error("Error fetching status:", error);
      toast({
        title: "Error",
        description: "Error fetching status.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    return null;
  };

  const handleGenerateContent = async (executionId) => {
    try {
      setIsGenerating(true); // Start spinner
      const payload = {
        program_id: selectedProgram._id,
        file_unique_id: selectedRow.file_unique_id,
      };
      const response = await axiosInstance.post(
        "/content-generation/generate-content",
        payload
      );

      if (response.status === 200) {
        fetchSelectedProgram(selectedProgram._id);
        toast({
          title: "Content Generation",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error generating content:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while generating content.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsGenerating(false); // Stop spinner
    }
  };

  const handleStopExecution = async (executionId) => {
    try {
      setLoadingStopId(executionId); // Start spinner
      const response = await axiosInstance.post(
        `/content-generation/stop-execution-by-execution-id/${selectedProgram._id}/${selectedRow.file_unique_id}/${executionId}`
      );
      if (response.status === 200) {
        await fetchStatus(executionId);
        setStoppedExecutionId(executionId); // Indicate stop was successful
        toast({
          title: "Execution Stopped",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error stopping execution:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while stopping execution.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingStopId(null); // Stop spinner
    }
  };

  const handleReExecuteContent = async (executionId) => {
    try {
      setLoadingReExecuteId(executionId); // Start spinner
      const response = await axiosInstance.post(
        `/content-generation/re-execute-content-by-execution-id/${selectedProgram._id}/${selectedRow.file_unique_id}/${executionId}`
      );
      if (response.status === 200) {
        await fetchStatus(executionId);
        toast({
          title: "Re-Execution Started",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error re-executing content:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while re-executing content.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingReExecuteId(null); // Stop spinner
    }
  };

  const handleRetryContent = async (executionId) => {
    try {
      setLoadingRetryId(executionId); // Start spinner
      const response = await axiosInstance.post(
        `/content-generation/re-try-content-by-execution-id/${selectedProgram._id}/${selectedRow.file_unique_id}/${executionId}`
      );
      if (response.status === 200) {
        await fetchStatus(executionId);
        toast({
          title: "Retry Started",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error retrying content:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while retrying content.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingRetryId(null); // Stop spinner
    }
  };

  const handleDelete = async () => {
    if (executionIdToDelete) {
      try {
        const response = await axiosInstance.delete(
          `/content-generation/delete-execution-by-execution-id/${selectedProgram._id}/${selectedRow.file_unique_id}/${executionIdToDelete}`
        );
        fetchSelectedProgram(selectedProgram._id);
        toast({
          title: "File deleted",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        setIsDeleteDialogOpen(false); // Close the dialog after deletion
      } catch (error) {
        console.error("Error deleting file:", error);
        let errorMessage = "An error occurred while deleting the file.";
        if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else if (error.message) {
          errorMessage = error.message;
        }
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  const confirmDelete = (executionId) => {
    setExecutionIdToDelete(executionId);
    setIsDeleteDialogOpen(true);
  };

  const onCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setExecutionIdToDelete(null);
  };
  return (
    <Flex
      direction="column"
      height="100vh"
      width={{ base: "100%", md: "100%" }}
    >
      <GenerationActions
        selectedProgram={selectedProgram}
        selectedRow={selectedRow}
        handleGenerateContent={handleGenerateContent}
        isGenerating={isGenerating}
      />
      <GenerationTable
        selectedProgram={selectedProgram}
        selectedRow={selectedRow}
        currentStatus={currentStatus}
        fetchStatus={fetchStatus}
        handleReExecuteContent={handleReExecuteContent}
        handleRetryContent={handleRetryContent}
        handleStopExecution={handleStopExecution}
        loadingReExecuteId={loadingReExecuteId}
        loadingRetryId={loadingRetryId}
        loadingStopId={loadingStopId}
        stoppedExecutionId={stoppedExecutionId}
        confirmDelete={confirmDelete}
        handleGenerateContent={handleGenerateContent}
        isGenerating={isGenerating}
      />
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={onCloseDeleteDialog}
        onDelete={handleDelete}
        cancelRef={cancelRef}
      />
    </Flex>
  );
};

export default GenerationView;
