import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../auth";
import { useGoogleAuth } from "./common/google/GoogleAuthContext";
import { useUser } from "./userRole/UserContext";

const ProtectedRoute = ({
  component: Component,
  roles: requiredRoles,
  requiresOTP,
  ...rest
}) => {
  const [logged] = useAuth();
  const { authState: { isLoggedIn } } = useGoogleAuth();
  const isUserLoggedIn = logged || isLoggedIn;
  const { user, loading } = useUser();
  //const { isOTPVerified } = useOTP();
  const userRole = user ? user.role : null;

  
  if (loading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isUserLoggedIn) {
          return <Redirect to="/login" />;
        }

        if (requiredRoles && !requiredRoles.includes(userRole)) {
          return <Redirect to="/login" />;
        }

      //  if (requiresOTP && !isOTPVerified) {
       //   return <AdminPanelAccess Component={Component} {...props} />;
       // }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;



// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { useAuth } from "../auth";
// import { useUser } from "./userRole/UserContext"; // <-- Import the user context hook

// const ProtectedRoute = ({
//   component: Component,
//   roles: requiredRoles,
//   ...rest
// }) => {
  // const [logged] = useAuth();
  // const { user, loading } = useUser(); // <-- Fetch the user and loading state from context
  // const userRole = user ? user.role : null;

//   // If the context is still loading (i.e., user data is being fetched), show a loading spinner or similar
//   if (loading) {
//     return null;
//   }

//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         // Check if logged in
//         if (!logged) {
//           return <Redirect to="/login" />;
//         }

//         // Check if the user has one of the required roles
//         if (requiredRoles && !requiredRoles.includes(userRole)) {
//           return <Redirect to="/unauthorized" />;
//         }

//         return <Component {...props} />;
//       }}
//     />
//   );
// };

// export default ProtectedRoute;


// const ProtectedRoute = ({
//   component: Component,
//   roles: requiredRoles,
//   module, // Pass the module name
//   ...rest
// }) => {
//   const [logged] = useAuth();
//   const { user, loading } = useUser();
//   const userRole = user ? user.role : null;
//   // const moduleAccess = user && user.moduleAccess && user.moduleAccess[module];

//   if (loading) {
//     return <div>Loading...</div>; // Or any loading indicator
//   }

//   // console.log("Logged:", logged);
//   // console.log("User Role:", userRole);
//   // console.log("Module Access for mailboxes:", moduleAccess);

//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (!logged) {
//           return <Redirect to="/login" />;
//         }

//         if (requiredRoles && !requiredRoles.includes(userRole)) {
//           return <Redirect to="/unauthorized" />;
//         }

//         // if (moduleAccess !== "allowed") {
//         //   return <Redirect to="/" />;
//         // }

//         return <Component {...props} />;
//       }}
//     />
//   );
// };

// export default ProtectedRoute;
